import utilTool from './util-tool';
import axios from 'axios';

const parseUploadImage = data => {
    // 如果是 File
    let suffix = '';
    if (data instanceof File) {
        suffix = data.type.split('/')[1];
        return [suffix, data];
    }
    if (typeof data === 'string') {
        suffix = data.match(/^data:image\/([^;]+);base64,/);
        if (suffix && suffix[1]) {
            return [suffix[1], convertBase64UrlToBlob(data)];
        }
    }
    console.error('file is invalid', data);
    return new Error('Invalid File ');
};

export const UploadImage = function(data, business = 'cmsbuild') {
    return new Promise((resolve, reject) => {
        const [type, file] = parseUploadImage(data);

        let date = new Date(),
            path = `/image/fs/${business}/${date.getFullYear()}${
                date.getMonth() + 1
            }${date.getDate()}/${date.getHours()}/original_${utilTool.uuidv4()}.${type}`;
        axios
            .get('/api-site/aws/token/presignurl', {
                params: {
                    business: business,
                    imageType: type,
                    path: path
                }
            })
            .then(rt => {
                const formData = new FormData();
                formData.append('url', rt.data.url);
                formData.append('type', rt.data.contentType);
                formData.append('file', file);
                axios({
                    url: '/api-site/aws/file/upload',
                    method: 'put',
                    timeout: 60000,
                    data: formData
                })
                    .then(() => {
                        resolve('https://cdn.chime.me' + path);
                    })
                    .catch(() => {
                        reject('Upload error.');
                    });
            });
    });
};

export const UploadFile = function(file) {
    return new Promise((resolve, reject) => {
        let filename = file.name.replace(/ +/g, '_');
        let date = new Date(),
            path = `/doc/fs/upload/${date.getFullYear()}${
                date.getMonth() + 1
            }${date.getDate()}/${date.getHours()}/${utilTool.uuidv4()}/${encodeURI(
                filename
            )}`;
        axios
            .get('/api-site/aws/token/presignurl', {
                params: {
                    path: path
                }
            })
            .then(rt => {
                // if (
                //     ['dev', 'development', 'proxy', 'proxy2'].includes(
                //         process.env.NODE_ENV
                //     )
                // ) {
                //     rt.data.url = rt.data.url.replace(
                //         'https://chime-file-repo.s3.us-west-1.amazonaws.com',
                //         '/upload-test'
                //     );
                //     rt.data.contentType = 'application/pdf';
                // }
                const formData = new FormData();
                formData.append('url', rt.data.url);
                formData.append('type', rt.data.contentType);
                formData.append('file', file);
                axios({
                    url: '/api-site/aws/file/upload',
                    method: 'put',
                    timeout: 60000,
                    data: formData
                })
                    .then(() => {
                        resolve('https://cdn.chime.me' + path);
                    })
                    .catch(e => {
                        console.error(e)
                        reject();
                    });
            });
    });
};

function convertBase64UrlToBlob(data) {
    // remove url head of ， and convert to byte
    let bytes = window.atob(data.split(',')[1]);
    // handle exception , Will ascii size less than 0 is converted to greater than 0
    let ab = new ArrayBuffer(bytes.length);
    let ia = new Uint8Array(ab);
    for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
}
