import axios from 'axios';

const COOKIE_FORM = {
    performance: "performance",
    functional: "functional",
    targeting: "targeting",
}
const COOKIE_LEVEL_KEYS = new Set(Object.values(COOKIE_FORM));

export const COOKIE_LEVEL = Object.freeze({
    necessary: "necessary",
    thirdParty: "thirdParty",
    ...COOKIE_FORM,
    dnt: "dnt",
    ca: "ca",
});



const COOKIE_KEY = {
    source: COOKIE_LEVEL.necessary,
    view_count: COOKIE_LEVEL.necessary,
    enableDynamicRegister: COOKIE_LEVEL.necessary,
    chatbox_visitor_session_id: COOKIE_LEVEL.necessary,
    chatbox_visitor_id: COOKIE_LEVEL.necessary,
    'site-search-listings': COOKIE_LEVEL.necessary,
    timezone: COOKIE_LEVEL.necessary,
    'sort_options_wont_hint': COOKIE_LEVEL.necessary,
    visitor_email: COOKIE_LEVEL.necessary,
    visitor_name: COOKIE_LEVEL.necessary,
    user_login_status: COOKIE_LEVEL.necessary,

    cpclid: COOKIE_LEVEL.performance,

    lang: COOKIE_LEVEL.functional,
    chimetrans: COOKIE_LEVEL.functional,
    chimetrans_gt: COOKIE_LEVEL.functional,
    chime_currency: COOKIE_LEVEL.functional,
    chime_currency_symbol: COOKIE_LEVEL.functional,
}
const COOKIE_STATUS = `cookie_status_settings`;


const COOKIE_SETTING_SWITCH = {
    [COOKIE_LEVEL.necessary]: true,
    [COOKIE_LEVEL.performance]: true,
    [COOKIE_LEVEL.functional]: true,
    [COOKIE_LEVEL.targeting]: true,
    [COOKIE_LEVEL.dnt]: false,
    [COOKIE_LEVEL.ca]: false,
};


const isAllowedCookieKey = ({ util, key }) => {
    if (key == COOKIE_STATUS) {
        return true;
    }
    let setting = getCookieSetting(util);
    let level = COOKIE_KEY[key];
    if (!level || setting[level] !== true) {
        console.log('cookie is not allowed', key, level);
        return false;
    }   
    return true;
}


export const getCookieSetting = (util) => {
    let settings =  util.getCookie(COOKIE_STATUS);
    if (!settings) {
        console.log("Cookie setting not found !!");
        return { ...COOKIE_SETTING_SWITCH };
    }
    try {
        return JSON.parse(decodeURIComponent(settings));
    } catch (e) {
        console.error("COOKIE_STATUS: Error parsing", settings);
    }
    return { ...COOKIE_SETTING_SWITCH };
}

export const removeCookie = (util) => {
    try {
        document.cookie.split(';').forEach(cookie => {
            const cookieName = cookie.split('=')[0].trim();
            if (COOKIE_KEY[cookieName] && !isAllowedCookieKey({ util, key: cookieName })) {
                // only frontend 
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            }
        });
    } catch (e) {
        console.error(e);
    }
}





export const proxyCookieUtil = (util) => {
    util.setCookie = new Proxy(util.setCookie, {
        apply: function(target, thisArg, argumentsList) {
            if (isAllowedCookieKey({ util, key: argumentsList[0] })) {
                const result = Reflect.apply(target, thisArg, argumentsList);
                return result;
            }
            // else if(COOKIE_KEY[argumentsList[0]]){
            //     registerSyncFun(()=>{
            //          target.apply(thisArg, argumentsList);
            //     },{
            //         level: COOKIE_KEY[argumentsList[0]],
            //         util,
            //     });
            // }
        }
    });
}


const filterOptions = (options) => {
    let obj = {};
    for (let key in options) {
        if (COOKIE_LEVEL_KEYS.has(key)) {
            obj[key] = options[key];
        }
    }
    return obj;
}

export const setCookieSetting = (options, util) => {
    options = filterOptions(options);
    return axios.post(`/api-site/website/cookie-setting${window.location.search}`, options).then((res) => {
        removeCookie(util);
        runSyncFun(util);
        return res;
    })
}

export const setCookieAll = (util, allowed) => {
    let setting = getCookieSetting(util);
    for (let key in setting) {
        setting[key] = !!allowed;
    }
    return setCookieSetting(setting, util);
}


const task_map = {

};
export const registerSyncFun = (fn, { level, util }) => {
    if (util.isSSRServer) {
        return;
    }
    if (!task_map[level]) {
        task_map[level] = [];
    }
    task_map[level].push(fn);
    runSyncFun(util)
}

const runSyncFun = (util) => {
    let setting = getCookieSetting(util);
    for (let level in task_map) {
        let list = task_map[level];
        if (setting[level]) {
            run(list);
        }
    }

    function run(list) {
        while (list.length > 0) {
            let fn = list.pop();
            try {
                fn && fn();
            } catch (e) {
                console.error(e);
            }
        }
    }
}