import { createApp } from 'vue';
import comingsoon from './index.vue';
import plugin from '@/plugins';
import util from '@/common/util';


export default (globalData, modules, axiosIns) => {
    let isSkipCheckPage = [
        'sold-compliance-check',
        'active-compliance-check',
    ].includes(globalData.pageKey);
    if (isSkipCheckPage) {
        localStorage.setItem('hide-coming-soon-temp' + globalData?.siteId, new Date().getTime());
    }
    let hasHide = localStorage.getItem('hide-coming-soon-' + globalData?.siteId);
    let hasHideTemp = localStorage.getItem('hide-coming-soon-temp' + globalData?.siteId);
    // Expired after 1 hour
    hasHideTemp = (Number(hasHideTemp) + 1000 * 60 * 60) > new Date().getTime()
    if (!hasHideTemp) {
        localStorage.removeItem('hide-coming-soon-temp' + globalData?.siteId)
    }
    let notPendingSite = globalData?.websiteInfo?.status !== 0;
    let isLandingPage = globalData.isLandingPage;
    
    if (
        isSkipCheckPage || 
        util.inCms || 
        hasHide || 
        hasHideTemp ||
        notPendingSite ||
        util.isScreenshot ||
        isLandingPage
    ) {
        return;
    }
    let app = createApp(comingsoon);
    plugin.baseInstall(app);
    app.mountSelf();
    app.ins?.open?.(globalData, modules, axiosIns)
}