<template>
    <div class="coming-soon" v-show="isShow">
        <div class="coming-soon-content">
            <div class="coming-soon-title">
                {{ $t('common:comingSoon.title') }}
            </div>
            <div class="coming-soon-desc">
                {{ $t('common:comingSoon.desc') }}
            </div>
            <div class="coming-soon-link" @click="viewSite">
                {{ $t('common:comingSoon.preview') }}
            
                <i class="iconfont icon-arrow_06"></i>
            </div>
        </div>
        <div class="coming-soon-social">
            <a
                v-for="(social, index) in socials"
                :key="social.id + '-' + index"
                class="share-item"
                :style="useCopyStyle"
                :href="social.url"
                :aria-label="social.socialNetwork"
                target="_blank"
                rel="noopener noreferrer"
            >
                <i class="iconfont" :class="social.icon"></i>
            </a>
        </div>
    </div>
</template>
<script>
import { sortAgentSocialNetworks } from '@/common/agents';


export default {

    data: function() {
        return {
            socials: [],
            isShow: true,
            siteId: 0
        }
    },
    methods: {
        viewSite() {
            localStorage.setItem('hide-coming-soon-' + this.siteId, true);
            this.enableScroll();
            this.isShow = false;
        }, 
        disableScroll() {
            document.body.style.overflow = 'hidden';
        },
        enableScroll() {
            document.body.style.overflow = 'auto';
        },
        open(globalData, modules, axiosIns) {
            this.siteId = globalData.siteId;
            let agentId = modules.find(module => module.name === 'md-footer')?.[0]?.data?.agentId || 0;
            let module = globalData.siteType === 3 || !globalData.isLandingPage ?  ',module:"footer"' : '';
            this.disableScroll()
            axiosIns?.post('/api-graphql', {
                query: `{agent(id:${agentId}${module}){firstName,lastName,image,headUrl:image,fullName,phone,email,position,license,agentSocialNetworks{id,url,socialNetwork},disclaimerList{fileName, queryLink},address,officeAddress}}`
            }).then(res => {
                let agent = { ...res.data?.agent };
                let socialNetworks = agent.agentSocialNetworks ? sortAgentSocialNetworks(agent.agentSocialNetworks) : [];
                socialNetworks = socialNetworks.filter(item => {
                    item.icon = 'icon-' + item.socialNetwork;
                    if (item.socialNetwork === 'facebook') {
                        item.icon = 'icon-facebook-sign';
                    }
                    if (item.socialNetwork === 'google+') {
                        item.icon = 'icon-google1';
                    }
                    if (item.socialNetwork === 'twitter') {
                        item.icon = 'icon-newnewtwitter';
                    }
                    return !!item.socialNetwork && !!item.url;
                });
                this.socials = socialNetworks;
            });
        }
    }
}
</script>
<style lang="scss">
@import '@/theme/media.scss';
.coming-soon.coming-soon{
    position: fixed;
    z-index: 999999999;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before{
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        background: url(//cdn.chime.me/image/fs/sitebuild/2024123/7/w800_original_f27475b1-dd51-4feb-b226-bab2f47a5718.png) no-repeat;
        background-position: center;
        background-size: cover;
        background-color: #cccccc;
    }
    &::after{
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        background: linear-gradient(118.52deg, rgba(25, 25, 25, 0) 42.96%, rgba(25, 25, 25, 0.5) 92.02%),
            linear-gradient(236.27deg, rgba(0, 0, 0, 0) 49.49%, rgba(0, 0, 0, 0.5) 109.41%),
            linear-gradient(64.18deg, rgba(0, 0, 0, 0) 38.86%, rgba(0, 0, 0, 0.5) 96.49%),
            linear-gradient(289.24deg, rgba(0, 0, 0, 0) 45.93%, rgba(0, 0, 0, 0.5) 99.15%),
            linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    }
    .coming-soon-content{
        position: relative;
        z-index: 1;
        color: #fff;
        width: 800px;
        text-align: center;
        .coming-soon-title{
            font-size: 90px;
            line-height: 1.5
        }
        .coming-soon-desc{
            font-size: 20px;
            line-height: 2;
            margin-top: 20px; 
        }
        .coming-soon-link{
            display: inline-flex;
            font-size: 16px;
            line-height: 2;
            margin-top: 30px;
            opacity: 0.7;
            cursor: pointer;
            position: relative;
            border-bottom: 1px solid #ffffff99;
            .iconfont{
                font-size: 20px;
                line-height: 32px;
                margin-left: 5px;
            }
            &::before{
                content: '';
                width: 0;
                height: 1px;
                position: absolute;
                left: 0;
                bottom: -1px;
                background: #fff;
                transition: .3s ease;
                display: block;
            }
            &:hover:before{
                width: 100%;
            }
        }
    }
    .coming-soon-social{
        position: absolute;
        bottom: 50px;
        color: #fff;
        z-index: 1;
        text-align: center;
        padding: 0 20px 0 10px;
        .share-item{
            width: 30px;
            height: 30px;
            border: 1px solid #fff;
            border-radius: 50%;
            margin-left: 10px;
            cursor: pointer;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            opacity: 0.7;
            margin-top: 10px;
            opacity: 0.5;
            &:hover{
                opacity: 1;
            }
        }
    }
    @include media-600 {
        .coming-soon-content{
            padding: 0 20px;
            .coming-soon-title{
                font-size: 45px;
            }
            .coming-soon-desc{
                font-size: 16px;
            }
            .coming-soon-link{
                font-size: 14px;
            }
        }
        .coming-soon-social{
            bottom: 30px;
        }

    }
}
</style>