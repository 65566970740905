export default {
    "md-accessibility": {
        "config": ()=>import(/* webpackChunkName: "modules/md-accessibility"*/"./md-accessibility"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-accessibility/broker" */"./md-accessibility/broker/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-accessibility/standard" */"./md-accessibility/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-accessibility/template1" */"./md-accessibility/template1/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-accessibility/template4" */"./md-accessibility/template4/index.vue")
    },
    "md-addon-box": {
        "config": ()=>import(/* webpackChunkName: "modules/md-addon-box"*/"./md-addon-box"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-addon-box/layout1" */"./md-addon-box/layout1/index.vue")
    },
    "md-agent-banner": {
        "config": ()=>import(/* webpackChunkName: "modules/md-agent-banner"*/"./md-agent-banner"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-agent-banner/fashion" */"./md-agent-banner/fashion/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-agent-banner/standard" */"./md-agent-banner/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-agent-banner/template1" */"./md-agent-banner/template1/index.vue"),
    "template5": ()=>import(/* webpackChunkName: "modules/md-agent-banner/template5" */"./md-agent-banner/template5/index.vue")
    },
    "md-agent-contact": {
        "config": ()=>import(/* webpackChunkName: "modules/md-agent-contact"*/"./md-agent-contact"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-agent-contact/broker" */"./md-agent-contact/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-agent-contact/classic" */"./md-agent-contact/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-agent-contact/fashion" */"./md-agent-contact/fashion/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-agent-contact/layout1" */"./md-agent-contact/layout1/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-agent-contact/layout2" */"./md-agent-contact/layout2/index.vue"),
    "layout3": ()=>import(/* webpackChunkName: "modules/md-agent-contact/layout3" */"./md-agent-contact/layout3/index.vue"),
    "layout4": ()=>import(/* webpackChunkName: "modules/md-agent-contact/layout4" */"./md-agent-contact/layout4/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-agent-contact/standard" */"./md-agent-contact/standard/index.vue"),
    "template8": ()=>import(/* webpackChunkName: "modules/md-agent-contact/template8" */"./md-agent-contact/template8/index.vue")
    },
    "md-agent-house": {
        "config": ()=>import(/* webpackChunkName: "modules/md-agent-house"*/"./md-agent-house"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-agent-house/broker" */"./md-agent-house/broker/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-agent-house/standard" */"./md-agent-house/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-agent-house/template1" */"./md-agent-house/template1/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-agent-house/template4" */"./md-agent-house/template4/index.vue")
    },
    "md-agent-search": {
        "config": ()=>import(/* webpackChunkName: "modules/md-agent-search"*/"./md-agent-search"),
    "layout6": ()=>import(/* webpackChunkName: "modules/md-agent-search/layout6" */"./md-agent-search/layout6/index.vue"),
    "template5": ()=>import(/* webpackChunkName: "modules/md-agent-search/template5" */"./md-agent-search/template5/index.vue")
    },
    "md-aggregation-content": {
        "config": ()=>import(/* webpackChunkName: "modules/md-aggregation-content"*/"./md-aggregation-content"),
    "pagination": ()=>import(/* webpackChunkName: "modules/md-aggregation-content/pagination" */"./md-aggregation-content/pagination/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-aggregation-content/standard" */"./md-aggregation-content/standard/index.vue")
    },
    "md-banner": {
        "config": ()=>import(/* webpackChunkName: "modules/md-banner"*/"./md-banner"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-banner/broker" */"./md-banner/broker/index.vue"),
    "has-line": ()=>import(/* webpackChunkName: "modules/md-banner/has-line" */"./md-banner/standard/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-banner/layout1" */"./md-banner/layout1/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-banner/layout2" */"./md-banner/layout2/index.vue"),
    "layout3": ()=>import(/* webpackChunkName: "modules/md-banner/layout3" */"./md-banner/layout3/index.vue"),
    "layout4": ()=>import(/* webpackChunkName: "modules/md-banner/layout4" */"./md-banner/layout4/index.vue"),
    "layout5": ()=>import(/* webpackChunkName: "modules/md-banner/layout5" */"./md-banner/layout5/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-banner/standard" */"./md-banner/standard/index.vue"),
    "template6": ()=>import(/* webpackChunkName: "modules/md-banner/template6" */"./md-banner/template6/index.vue"),
    "template8": ()=>import(/* webpackChunkName: "modules/md-banner/template8" */"./md-banner/template8/index.vue")
    },
    "md-blog": {
        "config": ()=>import(/* webpackChunkName: "modules/md-blog"*/"./md-blog"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-blog/broker" */"./md-blog/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-blog/classic" */"./md-blog/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-blog/fashion" */"./md-blog/fashion/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-blog/layout1" */"./md-blog/layout1/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-blog/layout2" */"./md-blog/layout2/index.vue"),
    "layout3": ()=>import(/* webpackChunkName: "modules/md-blog/layout3" */"./md-blog/layout3/index.vue"),
    "layout4": ()=>import(/* webpackChunkName: "modules/md-blog/layout4" */"./md-blog/layout4/index.vue"),
    "layout5": ()=>import(/* webpackChunkName: "modules/md-blog/layout5" */"./md-blog/layout5/index.vue"),
    "layout6": ()=>import(/* webpackChunkName: "modules/md-blog/layout6" */"./md-blog/layout6/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-blog/standard" */"./md-blog/standard/index.vue")
    },
    "md-blog-article": {
        "config": ()=>import(/* webpackChunkName: "modules/md-blog-article"*/"./md-blog-article"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-blog-article/broker" */"./md-blog-article/broker/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-blog-article/standard" */"./md-blog-article/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-blog-article/template1" */"./md-blog-article/template1/index.vue"),
    "template2": ()=>import(/* webpackChunkName: "modules/md-blog-article/template2" */"./md-blog-article/template2/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-blog-article/template4" */"./md-blog-article/template4/index.vue")
    },
    "md-blog-list": {
        "config": ()=>import(/* webpackChunkName: "modules/md-blog-list"*/"./md-blog-list"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-blog-list/broker" */"./md-blog-list/broker/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-blog-list/standard" */"./md-blog-list/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-blog-list/template1" */"./md-blog-list/template1/index.vue"),
    "template2": ()=>import(/* webpackChunkName: "modules/md-blog-list/template2" */"./md-blog-list/template2/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-blog-list/template4" */"./md-blog-list/template4/index.vue")
    },
    "md-building-info": {
        "config": ()=>import(/* webpackChunkName: "modules/md-building-info"*/"./md-building-info"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-building-info/broker" */"./md-building-info/broker/index.vue")
    },
    "md-building-listing-list": {
        "config": ()=>import(/* webpackChunkName: "modules/md-building-listing-list"*/"./md-building-listing-list"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-building-listing-list/broker" */"./md-building-listing-list/broker/index.vue")
    },
    "md-building-transportation": {
        "config": ()=>import(/* webpackChunkName: "modules/md-building-transportation"*/"./md-building-transportation"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-building-transportation/broker" */"./md-building-transportation/broker/index.vue")
    },
    "md-calculator": {
        "config": ()=>import(/* webpackChunkName: "modules/md-calculator"*/"./md-calculator"),
    "affordability": ()=>import(/* webpackChunkName: "modules/md-calculator/affordability" */"./md-calculator/affordability/index.vue"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-calculator/broker" */"./md-calculator/broker/index.vue"),
    "calculator": ()=>import(/* webpackChunkName: "modules/md-calculator/calculator" */"./md-calculator/calculator/index.vue"),
    "mortgage": ()=>import(/* webpackChunkName: "modules/md-calculator/mortgage" */"./md-calculator/mortgage/index.vue")
    },
    "md-calendar": {
        "config": ()=>import(/* webpackChunkName: "modules/md-calendar"*/"./md-calendar"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-calendar/broker" */"./md-calendar/broker/index.vue")
    },
    "md-compliance-check": {
        "config": ()=>import(/* webpackChunkName: "modules/md-compliance-check"*/"./md-compliance-check"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-compliance-check/broker" */"./md-compliance-check/broker/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-compliance-check/standard" */"./md-compliance-check/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-compliance-check/template1" */"./md-compliance-check/template1/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-compliance-check/template4" */"./md-compliance-check/template4/index.vue")
    },
    "md-content": {
        "config": ()=>import(/* webpackChunkName: "modules/md-content"*/"./md-content"),
    "broker-office-list": ()=>import(/* webpackChunkName: "modules/md-content/broker-office-list" */"./md-content/broker-office-list/index.vue"),
    "broker-web-content": ()=>import(/* webpackChunkName: "modules/md-content/broker-web-content" */"./md-content/broker-web-content/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-content/layout1" */"./md-content/layout1/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-content/layout2" */"./md-content/layout2/index.vue"),
    "richtext": ()=>import(/* webpackChunkName: "modules/md-content/richtext" */"./md-content/richtext/index.vue"),
    "seller-info": ()=>import(/* webpackChunkName: "modules/md-content/seller-info" */"./md-content/seller-info/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-content/standard" */"./md-content/standard/index.vue"),
    "three-column": ()=>import(/* webpackChunkName: "modules/md-content/three-column" */"./md-content/three-column/index.vue"),
    "two-column": ()=>import(/* webpackChunkName: "modules/md-content/two-column" */"./md-content/two-column/index.vue")
    },
    "md-cta": {
        "config": ()=>import(/* webpackChunkName: "modules/md-cta"*/"./md-cta"),
    "broker-two-column-standard": ()=>import(/* webpackChunkName: "modules/md-cta/broker-two-column-standard" */"./md-cta/broker-two-column-standard/index.vue"),
    "broker-two-column-template6": ()=>import(/* webpackChunkName: "modules/md-cta/broker-two-column-template6" */"./md-cta/broker-two-column-template6/index.vue"),
    "broker-two-column-template7": ()=>import(/* webpackChunkName: "modules/md-cta/broker-two-column-template7" */"./md-cta/broker-two-column-template7/index.vue"),
    "broker-two-column-template8": ()=>import(/* webpackChunkName: "modules/md-cta/broker-two-column-template8" */"./md-cta/broker-two-column-template8/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-cta/layout1" */"./md-cta/layout1/index.vue"),
    "layout10": ()=>import(/* webpackChunkName: "modules/md-cta/layout10" */"./md-cta/layout10/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-cta/layout2" */"./md-cta/layout2/index.vue"),
    "layout3": ()=>import(/* webpackChunkName: "modules/md-cta/layout3" */"./md-cta/layout3/index.vue"),
    "layout4": ()=>import(/* webpackChunkName: "modules/md-cta/layout4" */"./md-cta/layout4/index.vue"),
    "layout5": ()=>import(/* webpackChunkName: "modules/md-cta/layout5" */"./md-cta/layout5/index.vue"),
    "layout6": ()=>import(/* webpackChunkName: "modules/md-cta/layout6" */"./md-cta/layout6/index.vue"),
    "layout7": ()=>import(/* webpackChunkName: "modules/md-cta/layout7" */"./md-cta/layout7/index.vue"),
    "layout8": ()=>import(/* webpackChunkName: "modules/md-cta/layout8" */"./md-cta/layout8/index.vue"),
    "layout9": ()=>import(/* webpackChunkName: "modules/md-cta/layout9" */"./md-cta/layout9/index.vue"),
    "single-column-guide": ()=>import(/* webpackChunkName: "modules/md-cta/single-column-guide" */"./md-cta/single-column-guide/index.vue"),
    "single-column-standard": ()=>import(/* webpackChunkName: "modules/md-cta/single-column-standard" */"./md-cta/single-column-standard/index.vue"),
    "single-column-template7": ()=>import(/* webpackChunkName: "modules/md-cta/single-column-template7" */"./md-cta/single-column-template7/index.vue"),
    "single-column-template8": ()=>import(/* webpackChunkName: "modules/md-cta/single-column-template8" */"./md-cta/single-column-template8/index.vue")
    },
    "md-cta-broker-two-column": {
        "config": ()=>import(/* webpackChunkName: "modules/md-cta-broker-two-column"*/"./md-cta-broker-two-column"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-cta-broker-two-column/standard" */"./md-cta-broker-two-column/standard/index.vue"),
    "template6": ()=>import(/* webpackChunkName: "modules/md-cta-broker-two-column/template6" */"./md-cta-broker-two-column/template6/index.vue"),
    "template7": ()=>import(/* webpackChunkName: "modules/md-cta-broker-two-column/template7" */"./md-cta-broker-two-column/template7/index.vue"),
    "template8": ()=>import(/* webpackChunkName: "modules/md-cta-broker-two-column/template8" */"./md-cta-broker-two-column/template8/index.vue")
    },
    "md-cta-multiple-column": {
        "config": ()=>import(/* webpackChunkName: "modules/md-cta-multiple-column"*/"./md-cta-multiple-column"),
    "three-column": ()=>import(/* webpackChunkName: "modules/md-cta-multiple-column/three-column" */"./md-cta-multiple-column/three-column/index.vue"),
    "three-column-color-bg": ()=>import(/* webpackChunkName: "modules/md-cta-multiple-column/three-column-color-bg" */"./md-cta-multiple-column/three-column-color-bg/index.vue"),
    "three-column-no-layer": ()=>import(/* webpackChunkName: "modules/md-cta-multiple-column/three-column-no-layer" */"./md-cta-multiple-column/three-column-no-layer/index.vue"),
    "two-column": ()=>import(/* webpackChunkName: "modules/md-cta-multiple-column/two-column" */"./md-cta-multiple-column/two-column/index.vue")
    },
    "md-cta-single-column": {
        "config": ()=>import(/* webpackChunkName: "modules/md-cta-single-column"*/"./md-cta-single-column"),
    "guide": ()=>import(/* webpackChunkName: "modules/md-cta-single-column/guide" */"./md-cta-single-column/guide/index.vue"),
    "new": ()=>import(/* webpackChunkName: "modules/md-cta-single-column/new" */"./md-cta-single-column/new/index.vue"),
    "row": ()=>import(/* webpackChunkName: "modules/md-cta-single-column/row" */"./md-cta-single-column/row/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-cta-single-column/standard" */"./md-cta-single-column/standard/index.vue"),
    "template7": ()=>import(/* webpackChunkName: "modules/md-cta-single-column/template7" */"./md-cta-single-column/template7/index.vue"),
    "template8": ()=>import(/* webpackChunkName: "modules/md-cta-single-column/template8" */"./md-cta-single-column/template8/index.vue")
    },
    "md-cta-two-column-simple": {
        "config": ()=>import(/* webpackChunkName: "modules/md-cta-two-column-simple"*/"./md-cta-two-column-simple"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-cta-two-column-simple/classic" */"./md-cta-two-column-simple/classic/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-cta-two-column-simple/standard" */"./md-cta-two-column-simple/standard/index.vue")
    },
    "md-cta-two-column-single-copy": {
        "config": ()=>import(/* webpackChunkName: "modules/md-cta-two-column-single-copy"*/"./md-cta-two-column-single-copy"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-cta-two-column-single-copy/standard" */"./md-cta-two-column-single-copy/standard/index.vue")
    },
    "md-cta-two-column-two-copy": {
        "config": ()=>import(/* webpackChunkName: "modules/md-cta-two-column-two-copy"*/"./md-cta-two-column-two-copy"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-cta-two-column-two-copy/classic" */"./md-cta-two-column-two-copy/classic/index.vue"),
    "progressive": ()=>import(/* webpackChunkName: "modules/md-cta-two-column-two-copy/progressive" */"./md-cta-two-column-two-copy/progressive/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-cta-two-column-two-copy/standard" */"./md-cta-two-column-two-copy/standard/index.vue")
    },
    "md-custom-code": {
        "config": ()=>import(/* webpackChunkName: "modules/md-custom-code"*/"./md-custom-code"),
    "ai-img2code": ()=>import(/* webpackChunkName: "modules/md-custom-code/ai-img2code" */"./md-custom-code/ai-img2code/index.vue"),
    "federated": ()=>import(/* webpackChunkName: "modules/md-custom-code/federated" */"./md-custom-code/federated/index.vue"),
    "html-code": ()=>import(/* webpackChunkName: "modules/md-custom-code/html-code" */"./md-custom-code/html-code/index.vue")
    },
    "md-demographic": {
        "config": ()=>import(/* webpackChunkName: "modules/md-demographic"*/"./md-demographic"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-demographic/broker" */"./md-demographic/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-demographic/classic" */"./md-demographic/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-demographic/fashion" */"./md-demographic/fashion/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-demographic/standard" */"./md-demographic/standard/index.vue")
    },
    "md-detail-info": {
        "config": ()=>import(/* webpackChunkName: "modules/md-detail-info"*/"./md-detail-info"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-detail-info/broker" */"./md-detail-info/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-detail-info/classic" */"./md-detail-info/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-detail-info/fashion" */"./md-detail-info/fashion/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-detail-info/standard" */"./md-detail-info/standard/index.vue")
    },
    "md-detail-market-trends": {
        "config": ()=>import(/* webpackChunkName: "modules/md-detail-market-trends"*/"./md-detail-market-trends"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-detail-market-trends/classic" */"./md-detail-market-trends/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-detail-market-trends/fashion" */"./md-detail-market-trends/fashion/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-detail-market-trends/standard" */"./md-detail-market-trends/standard/index.vue")
    },
    "md-evaluation-banner": {
        "config": ()=>import(/* webpackChunkName: "modules/md-evaluation-banner"*/"./md-evaluation-banner"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-evaluation-banner/broker" */"./md-evaluation-banner/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-evaluation-banner/classic" */"./md-evaluation-banner/classic/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-evaluation-banner/layout1" */"./md-evaluation-banner/layout1/index.vue"),
    "new-seller": ()=>import(/* webpackChunkName: "modules/md-evaluation-banner/new-seller" */"./md-evaluation-banner/new-seller/index.vue"),
    "seller": ()=>import(/* webpackChunkName: "modules/md-evaluation-banner/seller" */"./md-evaluation-banner/seller/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-evaluation-banner/standard" */"./md-evaluation-banner/standard/index.vue"),
    "tips": ()=>import(/* webpackChunkName: "modules/md-evaluation-banner/tips" */"./md-evaluation-banner/tips/index.vue")
    },
    "md-featured-area": {
        "config": ()=>import(/* webpackChunkName: "modules/md-featured-area"*/"./md-featured-area"),
    "grid-broker": ()=>import(/* webpackChunkName: "modules/md-featured-area/grid-broker" */"./md-featured-area/grid-broker/index.vue"),
    "grid-classic": ()=>import(/* webpackChunkName: "modules/md-featured-area/grid-classic" */"./md-featured-area/grid-classic/index.vue"),
    "grid-fashion": ()=>import(/* webpackChunkName: "modules/md-featured-area/grid-fashion" */"./md-featured-area/grid-fashion/index.vue"),
    "grid-standard": ()=>import(/* webpackChunkName: "modules/md-featured-area/grid-standard" */"./md-featured-area/grid-standard/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-featured-area/layout1" */"./md-featured-area/layout1/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-featured-area/layout2" */"./md-featured-area/layout2/index.vue"),
    "layout3": ()=>import(/* webpackChunkName: "modules/md-featured-area/layout3" */"./md-featured-area/layout3/index.vue"),
    "layout4": ()=>import(/* webpackChunkName: "modules/md-featured-area/layout4" */"./md-featured-area/layout4/index.vue"),
    "layout5": ()=>import(/* webpackChunkName: "modules/md-featured-area/layout5" */"./md-featured-area/layout5/index.vue"),
    "layout6": ()=>import(/* webpackChunkName: "modules/md-featured-area/layout6" */"./md-featured-area/layout6/index.vue"),
    "layout7": ()=>import(/* webpackChunkName: "modules/md-featured-area/layout7" */"./md-featured-area/layout7/index.vue"),
    "layout8": ()=>import(/* webpackChunkName: "modules/md-featured-area/layout8" */"./md-featured-area/layout8/index.vue"),
    "template6": ()=>import(/* webpackChunkName: "modules/md-featured-area/template6" */"./md-featured-area/template6/index.vue"),
    "template7": ()=>import(/* webpackChunkName: "modules/md-featured-area/template7" */"./md-featured-area/template7/index.vue"),
    "template8": ()=>import(/* webpackChunkName: "modules/md-featured-area/template8" */"./md-featured-area/template8/index.vue")
    },
    "md-featured-area-crumb": {
        "config": ()=>import(/* webpackChunkName: "modules/md-featured-area-crumb"*/"./md-featured-area-crumb"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-featured-area-crumb/standard" */"./md-featured-area-crumb/standard/index.vue")
    },
    "md-featured-area-explore-listings": {
        "config": ()=>import(/* webpackChunkName: "modules/md-featured-area-explore-listings"*/"./md-featured-area-explore-listings"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-featured-area-explore-listings/broker" */"./md-featured-area-explore-listings/broker/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-featured-area-explore-listings/standard" */"./md-featured-area-explore-listings/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-featured-area-explore-listings/template1" */"./md-featured-area-explore-listings/template1/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-featured-area-explore-listings/template4" */"./md-featured-area-explore-listings/template4/index.vue")
    },
    "md-featured-area-near": {
        "config": ()=>import(/* webpackChunkName: "modules/md-featured-area-near"*/"./md-featured-area-near"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-featured-area-near/standard" */"./md-featured-area-near/standard/index.vue")
    },
    "md-footer": {
        "config": ()=>import(/* webpackChunkName: "modules/md-footer"*/"./md-footer"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-footer/broker" */"./md-footer/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-footer/classic" */"./md-footer/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-footer/fashion" */"./md-footer/fashion/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-footer/layout1" */"./md-footer/layout1/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-footer/layout2" */"./md-footer/layout2/index.vue"),
    "layout3": ()=>import(/* webpackChunkName: "modules/md-footer/layout3" */"./md-footer/layout3/index.vue"),
    "layout5": ()=>import(/* webpackChunkName: "modules/md-footer/layout5" */"./md-footer/layout5/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-footer/standard" */"./md-footer/standard/index.vue"),
    "upgrade": ()=>import(/* webpackChunkName: "modules/md-footer/upgrade" */"./md-footer/upgrade/index.vue")
    },
    "md-form": {
        "config": ()=>import(/* webpackChunkName: "modules/md-form"*/"./md-form"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-form/broker" */"./md-form/broker/index.vue"),
    "column": ()=>import(/* webpackChunkName: "modules/md-form/column" */"./md-form/column/index.vue"),
    "column-agent": ()=>import(/* webpackChunkName: "modules/md-form/column-agent" */"./md-form/column-agent/index.vue"),
    "column-double": ()=>import(/* webpackChunkName: "modules/md-form/column-double" */"./md-form/column-double/index.vue"),
    "embedded": ()=>import(/* webpackChunkName: "modules/md-form/embedded" */"./md-form/embedded/index.vue"),
    "float-layer": ()=>import(/* webpackChunkName: "modules/md-form/float-layer" */"./md-form/float-layer/index.vue"),
    "get-more-info-v1": ()=>import(/* webpackChunkName: "modules/md-form/get-more-info-v1" */"./md-form/get-more-info-v1/index.vue"),
    "get-more-info-v2": ()=>import(/* webpackChunkName: "modules/md-form/get-more-info-v2" */"./md-form/get-more-info-v2/index.vue"),
    "get-more-info-v3": ()=>import(/* webpackChunkName: "modules/md-form/get-more-info-v3" */"./md-form/get-more-info-v3/index.vue"),
    "get-more-info-v4": ()=>import(/* webpackChunkName: "modules/md-form/get-more-info-v4" */"./md-form/get-more-info-v4/index.vue"),
    "get-more-info-v5": ()=>import(/* webpackChunkName: "modules/md-form/get-more-info-v5" */"./md-form/get-more-info-v5/index.vue"),
    "get-more-info-v6": ()=>import(/* webpackChunkName: "modules/md-form/get-more-info-v6" */"./md-form/get-more-info-v6/index.vue"),
    "get-more-info-v7": ()=>import(/* webpackChunkName: "modules/md-form/get-more-info-v7" */"./md-form/get-more-info-v7/index.vue"),
    "get-more-info-v8": ()=>import(/* webpackChunkName: "modules/md-form/get-more-info-v8" */"./md-form/get-more-info-v8/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-form/layout1" */"./md-form/layout1/index.vue"),
    "layout10": ()=>import(/* webpackChunkName: "modules/md-form/layout10" */"./md-form/layout10/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-form/layout2" */"./md-form/layout2/index.vue"),
    "layout3": ()=>import(/* webpackChunkName: "modules/md-form/layout3" */"./md-form/layout3/index.vue"),
    "layout4": ()=>import(/* webpackChunkName: "modules/md-form/layout4" */"./md-form/layout4/index.vue"),
    "layout5": ()=>import(/* webpackChunkName: "modules/md-form/layout5" */"./md-form/layout5/index.vue"),
    "layout6": ()=>import(/* webpackChunkName: "modules/md-form/layout6" */"./md-form/layout6/index.vue"),
    "layout7": ()=>import(/* webpackChunkName: "modules/md-form/layout7" */"./md-form/layout7/index.vue"),
    "layout8": ()=>import(/* webpackChunkName: "modules/md-form/layout8" */"./md-form/layout8/index.vue"),
    "layout9": ()=>import(/* webpackChunkName: "modules/md-form/layout9" */"./md-form/layout9/index.vue"),
    "row-double": ()=>import(/* webpackChunkName: "modules/md-form/row-double" */"./md-form/row-double/index.vue"),
    "team-slider": ()=>import(/* webpackChunkName: "modules/md-form/team-slider" */"./md-form/team-slider/index.vue"),
    "template7": ()=>import(/* webpackChunkName: "modules/md-form/template7" */"./md-form/template7/index.vue")
    },
    "md-gallery": {
        "config": ()=>import(/* webpackChunkName: "modules/md-gallery"*/"./md-gallery"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-gallery/layout1" */"./md-gallery/layout1/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-gallery/layout2" */"./md-gallery/layout2/index.vue"),
    "layout3": ()=>import(/* webpackChunkName: "modules/md-gallery/layout3" */"./md-gallery/layout3/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-gallery/standard" */"./md-gallery/standard/index.vue"),
    "template8": ()=>import(/* webpackChunkName: "modules/md-gallery/template8" */"./md-gallery/template8/index.vue")
    },
    "md-guide": {
        "config": ()=>import(/* webpackChunkName: "modules/md-guide"*/"./md-guide"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-guide/standard" */"./md-guide/standard/index.vue")
    },
    "md-header": {
        "config": ()=>import(/* webpackChunkName: "modules/md-header"*/"./md-header"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-header/broker" */"./md-header/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-header/classic" */"./md-header/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-header/fashion" */"./md-header/fashion/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-header/layout1" */"./md-header/layout1/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-header/layout2" */"./md-header/layout2/index.vue"),
    "layout3": ()=>import(/* webpackChunkName: "modules/md-header/layout3" */"./md-header/layout3/index.vue"),
    "layout4": ()=>import(/* webpackChunkName: "modules/md-header/layout4" */"./md-header/layout4/index.vue"),
    "layout5": ()=>import(/* webpackChunkName: "modules/md-header/layout5" */"./md-header/layout5/index.vue"),
    "layout6": ()=>import(/* webpackChunkName: "modules/md-header/layout6" */"./md-header/layout6/index.vue"),
    "progressive": ()=>import(/* webpackChunkName: "modules/md-header/progressive" */"./md-header/progressive/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-header/standard" */"./md-header/standard/index.vue"),
    "template5": ()=>import(/* webpackChunkName: "modules/md-header/template5" */"./md-header/template5/index.vue"),
    "template6": (searchCenter, isMobile)=>isMobile ? import(/* webpackChunkName: "modules/md-header-mobile/template6" */"./md-header/template6/index-mobile.vue") : import(/* webpackChunkName: "modules/md-header-pc/template6" */"./md-header/template6/index-pc.vue"),
    "template7": ()=>import(/* webpackChunkName: "modules/md-header/template7" */"./md-header/template7/index.vue")
    },
    "md-header-pp": {
        "config": ()=>import(/* webpackChunkName: "modules/md-header-pp"*/"./md-header-pp"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-header-pp/standard" */"./md-header-pp/standard/index.vue")
    },
    "md-header-simple": {
        "config": ()=>import(/* webpackChunkName: "modules/md-header-simple"*/"./md-header-simple"),
    "he": ()=>import(/* webpackChunkName: "modules/md-header-simple/he" */"./md-header-simple/he/index.vue"),
    "lg": ()=>import(/* webpackChunkName: "modules/md-header-simple/lg" */"./md-header-simple/lg/index.vue")
    },
    "md-hero": {
        "config": ()=>import(/* webpackChunkName: "modules/md-hero"*/"./md-hero"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-hero/broker" */"./md-hero/broker/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-hero/layout1" */"./md-hero/layout1/index.vue"),
    "layout10": ()=>import(/* webpackChunkName: "modules/md-hero/layout10" */"./md-hero/layout10/index.vue"),
    "layout11": ()=>import(/* webpackChunkName: "modules/md-hero/layout11" */"./md-hero/layout11/index.vue"),
    "layout12": ()=>import(/* webpackChunkName: "modules/md-hero/layout12" */"./md-hero/layout12/index.vue"),
    "layout13": ()=>import(/* webpackChunkName: "modules/md-hero/layout13" */"./md-hero/layout13/index.vue"),
    "layout14": ()=>import(/* webpackChunkName: "modules/md-hero/layout14" */"./md-hero/layout14/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-hero/layout2" */"./md-hero/layout2/index.vue"),
    "layout3": ()=>import(/* webpackChunkName: "modules/md-hero/layout3" */"./md-hero/layout3/index.vue"),
    "layout4": ()=>import(/* webpackChunkName: "modules/md-hero/layout4" */"./md-hero/layout4/index.vue"),
    "layout5": ()=>import(/* webpackChunkName: "modules/md-hero/layout5" */"./md-hero/layout5/index.vue"),
    "layout9": ()=>import(/* webpackChunkName: "modules/md-hero/layout9" */"./md-hero/layout9/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-hero/standard" */"./md-hero/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-hero/template1" */"./md-hero/template1/index.vue"),
    "template2": ()=>import(/* webpackChunkName: "modules/md-hero/template2" */"./md-hero/template2/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-hero/template4" */"./md-hero/template4/index.vue"),
    "template6": ()=>import(/* webpackChunkName: "modules/md-hero/template6" */"./md-hero/template6/index.vue"),
    "template7": ()=>import(/* webpackChunkName: "modules/md-hero/template7" */"./md-hero/template7/index.vue"),
    "template8": ()=>import(/* webpackChunkName: "modules/md-hero/template8" */"./md-hero/template8/index.vue")
    },
    "md-home-mortgage": {
        "config": ()=>import(/* webpackChunkName: "modules/md-home-mortgage"*/"./md-home-mortgage"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-home-mortgage/standard" */"./md-home-mortgage/standard/index.vue")
    },
    "md-house": {
        "config": ()=>import(/* webpackChunkName: "modules/md-house"*/"./md-house"),
    "broker-grid": ()=>import(/* webpackChunkName: "modules/md-house/broker-grid" */"./md-house/broker-grid/index.vue"),
    "broker-list": ()=>import(/* webpackChunkName: "modules/md-house/broker-list" */"./md-house/broker-list/index.vue"),
    "gallery": ()=>import(/* webpackChunkName: "modules/md-house/gallery" */"./md-house/gallery/index.vue"),
    "grid-v1": ()=>import(/* webpackChunkName: "modules/md-house/grid-v1" */"./md-house/grid-v1/index.vue"),
    "grid-v2": ()=>import(/* webpackChunkName: "modules/md-house/grid-v2" */"./md-house/grid-v2/index.vue"),
    "grid-v3": ()=>import(/* webpackChunkName: "modules/md-house/grid-v3" */"./md-house/grid-v3/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-house/layout1" */"./md-house/layout1/index.vue"),
    "layout10": ()=>import(/* webpackChunkName: "modules/md-house/layout10" */"./md-house/layout10/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-house/layout2" */"./md-house/layout2/index.vue"),
    "layout3": ()=>import(/* webpackChunkName: "modules/md-house/layout3" */"./md-house/layout3/index.vue"),
    "layout4": ()=>import(/* webpackChunkName: "modules/md-house/layout4" */"./md-house/layout4/index.vue"),
    "layout5": ()=>import(/* webpackChunkName: "modules/md-house/layout5" */"./md-house/layout5/index.vue"),
    "layout6": ()=>import(/* webpackChunkName: "modules/md-house/layout6" */"./md-house/layout6/index.vue"),
    "layout7": ()=>import(/* webpackChunkName: "modules/md-house/layout7" */"./md-house/layout7/index.vue"),
    "layout8": ()=>import(/* webpackChunkName: "modules/md-house/layout8" */"./md-house/layout8/index.vue"),
    "layout9": ()=>import(/* webpackChunkName: "modules/md-house/layout9" */"./md-house/layout9/index.vue"),
    "list-v1": ()=>import(/* webpackChunkName: "modules/md-house/list-v1" */"./md-house/list-v1/index.vue"),
    "list-v2": ()=>import(/* webpackChunkName: "modules/md-house/list-v2" */"./md-house/list-v2/index.vue"),
    "list-v3": ()=>import(/* webpackChunkName: "modules/md-house/list-v3" */"./md-house/list-v3/index.vue"),
    "template6-slider": ()=>import(/* webpackChunkName: "modules/md-house/template6-slider" */"./md-house/template6-slider/index.vue"),
    "template8": ()=>import(/* webpackChunkName: "modules/md-house/template8" */"./md-house/template8/index.vue")
    },
    "md-house-detail": {
        "config": ()=>import(/* webpackChunkName: "modules/md-house-detail"*/"./md-house-detail"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-house-detail/broker" */"./md-house-detail/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-house-detail/classic" */"./md-house-detail/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-house-detail/fashion" */"./md-house-detail/fashion/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-house-detail/standard" */"./md-house-detail/standard/index.vue")
    },
    "md-house-facts": {
        "config": ()=>import(/* webpackChunkName: "modules/md-house-facts"*/"./md-house-facts"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-house-facts/broker" */"./md-house-facts/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-house-facts/classic" */"./md-house-facts/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-house-facts/fashion" */"./md-house-facts/fashion/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-house-facts/standard" */"./md-house-facts/standard/index.vue")
    },
    "md-house-listing": {
        "config": ()=>import(/* webpackChunkName: "modules/md-house-listing"*/"./md-house-listing"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-house-listing/broker" */"./md-house-listing/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-house-listing/classic" */"./md-house-listing/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-house-listing/fashion" */"./md-house-listing/fashion/index.vue"),
    "pp": ()=>import(/* webpackChunkName: "modules/md-house-listing/pp" */"./md-house-listing/pp/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-house-listing/standard" */"./md-house-listing/standard/index.vue")
    },
    "md-interestingfact": {
        "config": ()=>import(/* webpackChunkName: "modules/md-interestingfact"*/"./md-interestingfact"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-interestingfact/template1" */"./md-interestingfact/template1/index.vue")
    },
    "md-invalid": {
        "config": ()=>import(/* webpackChunkName: "modules/md-invalid"*/"./md-invalid"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-invalid/standard" */"./md-invalid/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-invalid/template1" */"./md-invalid/template1/index.vue"),
    "unfind": ()=>import(/* webpackChunkName: "modules/md-invalid/unfind" */"./md-invalid/unfind/index.vue")
    },
    "md-lead-form": {
        "config": ()=>import(/* webpackChunkName: "modules/md-lead-form"*/"./md-lead-form"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-lead-form/standard" */"./md-lead-form/standard/index.vue")
    },
    "md-lenders": {
        "config": ()=>import(/* webpackChunkName: "modules/md-lenders"*/"./md-lenders"),
    "single-column-more-info": ()=>import(/* webpackChunkName: "modules/md-lenders/single-column-more-info" */"./md-lenders/single-column-more-info/index.vue")
    },
    "md-list-your-home": {
        "config": ()=>import(/* webpackChunkName: "modules/md-list-your-home"*/"./md-list-your-home"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-list-your-home/standard" */"./md-list-your-home/standard/index.vue")
    },
    "md-listing-list": {
        "config": ()=>import(/* webpackChunkName: "modules/md-listing-list"*/"./md-listing-list"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-listing-list/broker" */"./md-listing-list/broker/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-listing-list/standard" */"./md-listing-list/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-listing-list/template1" */"./md-listing-list/template1/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-listing-list/template4" */"./md-listing-list/template4/index.vue")
    },
    "md-live": {
        "config": ()=>import(/* webpackChunkName: "modules/md-live"*/"./md-live"),
    "facebook": ()=>import(/* webpackChunkName: "modules/md-live/facebook" */"./md-live/facebook/index.vue"),
    "youtube": ()=>import(/* webpackChunkName: "modules/md-live/youtube" */"./md-live/youtube/index.vue"),
    "zoom": ()=>import(/* webpackChunkName: "modules/md-live/zoom" */"./md-live/zoom/index.vue")
    },
    "md-map": {
        "config": ()=>import(/* webpackChunkName: "modules/md-map"*/"./md-map"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-map/broker" */"./md-map/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-map/classic" */"./md-map/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-map/fashion" */"./md-map/fashion/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-map/standard" */"./md-map/standard/index.vue")
    },
    "md-market-trends": {
        "config": ()=>import(/* webpackChunkName: "modules/md-market-trends"*/"./md-market-trends"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-market-trends/broker" */"./md-market-trends/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-market-trends/classic" */"./md-market-trends/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-market-trends/fashion" */"./md-market-trends/fashion/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-market-trends/layout1" */"./md-market-trends/layout1/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-market-trends/standard" */"./md-market-trends/standard/index.vue")
    },
    "md-mid-banner": {
        "config": ()=>import(/* webpackChunkName: "modules/md-mid-banner"*/"./md-mid-banner"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-mid-banner/standard" */"./md-mid-banner/standard/index.vue")
    },
    "md-nbr-demographics": {
        "config": ()=>import(/* webpackChunkName: "modules/md-nbr-demographics"*/"./md-nbr-demographics"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-nbr-demographics/broker" */"./md-nbr-demographics/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-nbr-demographics/classic" */"./md-nbr-demographics/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-nbr-demographics/fashion" */"./md-nbr-demographics/fashion/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-nbr-demographics/standard" */"./md-nbr-demographics/standard/index.vue")
    },
    "md-nbr-form": {
        "config": ()=>import(/* webpackChunkName: "modules/md-nbr-form"*/"./md-nbr-form"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-nbr-form/broker" */"./md-nbr-form/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-nbr-form/classic" */"./md-nbr-form/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-nbr-form/fashion" */"./md-nbr-form/fashion/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-nbr-form/standard" */"./md-nbr-form/standard/index.vue")
    },
    "md-nbr-info": {
        "config": ()=>import(/* webpackChunkName: "modules/md-nbr-info"*/"./md-nbr-info"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-nbr-info/broker" */"./md-nbr-info/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-nbr-info/classic" */"./md-nbr-info/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-nbr-info/fashion" */"./md-nbr-info/fashion/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-nbr-info/standard" */"./md-nbr-info/standard/index.vue")
    },
    "md-nbr-local": {
        "config": ()=>import(/* webpackChunkName: "modules/md-nbr-local"*/"./md-nbr-local"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-nbr-local/broker" */"./md-nbr-local/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-nbr-local/classic" */"./md-nbr-local/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-nbr-local/fashion" */"./md-nbr-local/fashion/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-nbr-local/standard" */"./md-nbr-local/standard/index.vue")
    },
    "md-nbr-market": {
        "config": ()=>import(/* webpackChunkName: "modules/md-nbr-market"*/"./md-nbr-market"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-nbr-market/classic" */"./md-nbr-market/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-nbr-market/fashion" */"./md-nbr-market/fashion/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-nbr-market/standard" */"./md-nbr-market/standard/index.vue")
    },
    "md-office-detail": {
        "config": ()=>import(/* webpackChunkName: "modules/md-office-detail"*/"./md-office-detail"),
    "template5": ()=>import(/* webpackChunkName: "modules/md-office-detail/template5" */"./md-office-detail/template5/index.vue")
    },
    "md-office-search": {
        "config": ()=>import(/* webpackChunkName: "modules/md-office-search"*/"./md-office-search"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-office-search/broker" */"./md-office-search/broker/index.vue"),
    "template5": ()=>import(/* webpackChunkName: "modules/md-office-search/template5" */"./md-office-search/template5/index.vue")
    },
    "md-partner": {
        "config": ()=>import(/* webpackChunkName: "modules/md-partner"*/"./md-partner"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-partner/broker" */"./md-partner/broker/index.vue"),
    "flatten": ()=>import(/* webpackChunkName: "modules/md-partner/flatten" */"./md-partner/flatten/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-partner/layout1" */"./md-partner/layout1/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-partner/layout2" */"./md-partner/layout2/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-partner/standard" */"./md-partner/standard/index.vue")
    },
    "md-password": {
        "config": ()=>import(/* webpackChunkName: "modules/md-password"*/"./md-password"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-password/standard" */"./md-password/standard/index.vue")
    },
    "md-pp-banner": {
        "config": ()=>import(/* webpackChunkName: "modules/md-pp-banner"*/"./md-pp-banner"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-pp-banner/standard" */"./md-pp-banner/standard/index.vue")
    },
    "md-pp-detail": {
        "config": ()=>import(/* webpackChunkName: "modules/md-pp-detail"*/"./md-pp-detail"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-pp-detail/standard" */"./md-pp-detail/standard/index.vue")
    },
    "md-pp-gallery": {
        "config": ()=>import(/* webpackChunkName: "modules/md-pp-gallery"*/"./md-pp-gallery"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-pp-gallery/standard" */"./md-pp-gallery/standard/index.vue")
    },
    "md-pp-video": {
        "config": ()=>import(/* webpackChunkName: "modules/md-pp-video"*/"./md-pp-video"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-pp-video/standard" */"./md-pp-video/standard/index.vue")
    },
    "md-privacy-terms": {
        "config": ()=>import(/* webpackChunkName: "modules/md-privacy-terms"*/"./md-privacy-terms"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-privacy-terms/layout1" */"./md-privacy-terms/layout1/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-privacy-terms/standard" */"./md-privacy-terms/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-privacy-terms/template1" */"./md-privacy-terms/template1/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-privacy-terms/template4" */"./md-privacy-terms/template4/index.vue")
    },
    "md-profile": {
        "config": ()=>import(/* webpackChunkName: "modules/md-profile"*/"./md-profile"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-profile/broker" */"./md-profile/broker/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-profile/standard" */"./md-profile/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-profile/template1" */"./md-profile/template1/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-profile/template4" */"./md-profile/template4/index.vue")
    },
    "md-property-history": {
        "config": ()=>import(/* webpackChunkName: "modules/md-property-history"*/"./md-property-history"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-property-history/broker" */"./md-property-history/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-property-history/classic" */"./md-property-history/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-property-history/fashion" */"./md-property-history/fashion/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-property-history/standard" */"./md-property-history/standard/index.vue")
    },
    "md-property-promotion": {
        "config": ()=>import(/* webpackChunkName: "modules/md-property-promotion"*/"./md-property-promotion"),
    "grid": ()=>import(/* webpackChunkName: "modules/md-property-promotion/grid" */"./md-property-promotion/grid/index.vue"),
    "list": ()=>import(/* webpackChunkName: "modules/md-property-promotion/list" */"./md-property-promotion/list/index.vue")
    },
    "md-quick-search": {
        "config": ()=>import(/* webpackChunkName: "modules/md-quick-search"*/"./md-quick-search"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-quick-search/broker" */"./md-quick-search/broker/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-quick-search/layout1" */"./md-quick-search/layout1/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-quick-search/standard" */"./md-quick-search/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-quick-search/template1" */"./md-quick-search/template1/index.vue"),
    "template2": ()=>import(/* webpackChunkName: "modules/md-quick-search/template2" */"./md-quick-search/template2/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-quick-search/template4" */"./md-quick-search/template4/index.vue")
    },
    "md-school-map": {
        "config": ()=>import(/* webpackChunkName: "modules/md-school-map"*/"./md-school-map"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-school-map/standard" */"./md-school-map/standard/index.vue")
    },
    "md-search": {
        "config": ()=>import(/* webpackChunkName: "modules/md-search"*/"./md-search"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-search/broker" */"./md-search/broker/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-search/standard" */"./md-search/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-search/template1" */"./md-search/template1/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-search/template4" */"./md-search/template4/index.vue")
    },
    "md-search-bar": {
        "config": ()=>import(/* webpackChunkName: "modules/md-search-bar"*/"./md-search-bar"),
    "broker": (searchCenter)=>searchCenter ? import(/* webpackChunkName: "modules/md-search-bar-center/broker" */"./md-search-bar/broker/index-center.vue") : import(/* webpackChunkName: "modules/md-search-bar/broker" */"./md-search-bar/broker/index.vue"),
    "layout1": (searchCenter)=>searchCenter ? import(/* webpackChunkName: "modules/md-search-bar-center/layout1" */"./md-search-bar/layout1/index-center.vue") : import(/* webpackChunkName: "modules/md-search-bar/layout1" */"./md-search-bar/layout1/index.vue"),
    "standard": (searchCenter)=>searchCenter ? import(/* webpackChunkName: "modules/md-search-bar-center/standard" */"./md-search-bar/standard/index-center.vue") : import(/* webpackChunkName: "modules/md-search-bar/standard" */"./md-search-bar/standard/index.vue"),
    "template1": (searchCenter)=>searchCenter ? import(/* webpackChunkName: "modules/md-search-bar-center/template1" */"./md-search-bar/template1/index-center.vue") : import(/* webpackChunkName: "modules/md-search-bar/template1" */"./md-search-bar/template1/index.vue"),
    "template4": (searchCenter)=>searchCenter ? import(/* webpackChunkName: "modules/md-search-bar-center/template4" */"./md-search-bar/template4/index-center.vue") : import(/* webpackChunkName: "modules/md-search-bar/template4" */"./md-search-bar/template4/index.vue")
    },
    "md-search-big-map": {
        "config": ()=>import(/* webpackChunkName: "modules/md-search-big-map"*/"./md-search-big-map"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-search-big-map/broker" */"./md-search-big-map/broker/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-search-big-map/standard" */"./md-search-big-map/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-search-big-map/template1" */"./md-search-big-map/template1/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-search-big-map/template4" */"./md-search-big-map/template4/index.vue")
    },
    "md-search-grid-list": {
        "config": ()=>import(/* webpackChunkName: "modules/md-search-grid-list"*/"./md-search-grid-list"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-search-grid-list/broker" */"./md-search-grid-list/broker/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-search-grid-list/standard" */"./md-search-grid-list/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-search-grid-list/template1" */"./md-search-grid-list/template1/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-search-grid-list/template4" */"./md-search-grid-list/template4/index.vue")
    },
    "md-search-result": {
        "config": ()=>import(/* webpackChunkName: "modules/md-search-result"*/"./md-search-result"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-search-result/broker" */"./md-search-result/broker/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-search-result/standard" */"./md-search-result/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-search-result/template1" */"./md-search-result/template1/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-search-result/template4" */"./md-search-result/template4/index.vue")
    },
    "md-search-seo": {
        "config": ()=>import(/* webpackChunkName: "modules/md-search-seo"*/"./md-search-seo"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-search-seo/broker" */"./md-search-seo/broker/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-search-seo/standard" */"./md-search-seo/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-search-seo/template1" */"./md-search-seo/template1/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-search-seo/template4" */"./md-search-seo/template4/index.vue")
    },
    "md-sell-banner": {
        "config": ()=>import(/* webpackChunkName: "modules/md-sell-banner"*/"./md-sell-banner"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-sell-banner/broker" */"./md-sell-banner/broker/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-sell-banner/fashion" */"./md-sell-banner/fashion/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-sell-banner/layout1" */"./md-sell-banner/layout1/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-sell-banner/standard" */"./md-sell-banner/standard/index.vue")
    },
    "md-sell-form": {
        "config": ()=>import(/* webpackChunkName: "modules/md-sell-form"*/"./md-sell-form"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-sell-form/standard" */"./md-sell-form/standard/index.vue")
    },
    "md-sell-process": {
        "config": ()=>import(/* webpackChunkName: "modules/md-sell-process"*/"./md-sell-process"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-sell-process/broker" */"./md-sell-process/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-sell-process/classic" */"./md-sell-process/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-sell-process/fashion" */"./md-sell-process/fashion/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-sell-process/layout1" */"./md-sell-process/layout1/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-sell-process/standard" */"./md-sell-process/standard/index.vue")
    },
    "md-similar-home": {
        "config": ()=>import(/* webpackChunkName: "modules/md-similar-home"*/"./md-similar-home"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-similar-home/classic" */"./md-similar-home/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-similar-home/fashion" */"./md-similar-home/fashion/index.vue"),
    "pp": ()=>import(/* webpackChunkName: "modules/md-similar-home/pp" */"./md-similar-home/pp/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-similar-home/standard" */"./md-similar-home/standard/index.vue")
    },
    "md-snapshot-search": {
        "config": ()=>import(/* webpackChunkName: "modules/md-snapshot-search"*/"./md-snapshot-search"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-snapshot-search/broker" */"./md-snapshot-search/broker/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-snapshot-search/standard" */"./md-snapshot-search/standard/index.vue"),
    "template2": ()=>import(/* webpackChunkName: "modules/md-snapshot-search/template2" */"./md-snapshot-search/template2/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-snapshot-search/template4" */"./md-snapshot-search/template4/index.vue")
    },
    "md-social-post": {
        "config": ()=>import(/* webpackChunkName: "modules/md-social-post"*/"./md-social-post"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-social-post/standard" */"./md-social-post/standard/index.vue")
    },
    "md-sold-detail": {
        "config": ()=>import(/* webpackChunkName: "modules/md-sold-detail"*/"./md-sold-detail"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-sold-detail/broker" */"./md-sold-detail/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-sold-detail/classic" */"./md-sold-detail/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-sold-detail/fashion" */"./md-sold-detail/fashion/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-sold-detail/standard" */"./md-sold-detail/standard/index.vue")
    },
    "md-sold-estimate": {
        "config": ()=>import(/* webpackChunkName: "modules/md-sold-estimate"*/"./md-sold-estimate"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-sold-estimate/broker" */"./md-sold-estimate/broker/index.vue"),
    "classic": ()=>import(/* webpackChunkName: "modules/md-sold-estimate/classic" */"./md-sold-estimate/classic/index.vue"),
    "fashion": ()=>import(/* webpackChunkName: "modules/md-sold-estimate/fashion" */"./md-sold-estimate/fashion/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-sold-estimate/standard" */"./md-sold-estimate/standard/index.vue")
    },
    "md-successful-activation": {
        "config": ()=>import(/* webpackChunkName: "modules/md-successful-activation"*/"./md-successful-activation"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-successful-activation/standard" */"./md-successful-activation/standard/index.vue")
    },
    "md-team": {
        "config": ()=>import(/* webpackChunkName: "modules/md-team"*/"./md-team"),
    "agent-directory": ()=>import(/* webpackChunkName: "modules/md-team/agent-directory" */"./md-team/agent-directory/index.vue"),
    "broker-float": ()=>import(/* webpackChunkName: "modules/md-team/broker-float" */"./md-team/broker-float/index.vue"),
    "broker-list": ()=>import(/* webpackChunkName: "modules/md-team/broker-list" */"./md-team/broker-list/index.vue"),
    "broker-slider": ()=>import(/* webpackChunkName: "modules/md-team/broker-slider" */"./md-team/broker-slider/index.vue"),
    "carousel": ()=>import(/* webpackChunkName: "modules/md-team/carousel" */"./md-team/carousel/index.vue"),
    "carousel-detail": ()=>import(/* webpackChunkName: "modules/md-team/carousel-detail" */"./md-team/carousel-detail/index.vue"),
    "carousel-detail-fashion": ()=>import(/* webpackChunkName: "modules/md-team/carousel-detail-fashion" */"./md-team/carousel-detail-fashion/index.vue"),
    "carousel-fashion": ()=>import(/* webpackChunkName: "modules/md-team/carousel-fashion" */"./md-team/carousel-fashion/index.vue"),
    "carousel-tpl6-detail": ()=>import(/* webpackChunkName: "modules/md-team/carousel-tpl6-detail" */"./md-team/carousel-tpl6-detail/index.vue"),
    "double-column-long-intro": ()=>import(/* webpackChunkName: "modules/md-team/double-column-long-intro" */"./md-team/double-column-long-intro/index.vue"),
    "double-column-short-intro": ()=>import(/* webpackChunkName: "modules/md-team/double-column-short-intro" */"./md-team/double-column-short-intro/index.vue"),
    "double-column-simple": ()=>import(/* webpackChunkName: "modules/md-team/double-column-simple" */"./md-team/double-column-simple/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-team/layout1" */"./md-team/layout1/index.vue"),
    "layout10": ()=>import(/* webpackChunkName: "modules/md-team/layout10" */"./md-team/layout10/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-team/layout2" */"./md-team/layout2/index.vue"),
    "layout3": ()=>import(/* webpackChunkName: "modules/md-team/layout3" */"./md-team/layout3/index.vue"),
    "layout4": ()=>import(/* webpackChunkName: "modules/md-team/layout4" */"./md-team/layout4/index.vue"),
    "layout5": ()=>import(/* webpackChunkName: "modules/md-team/layout5" */"./md-team/layout5/index.vue"),
    "layout7": ()=>import(/* webpackChunkName: "modules/md-team/layout7" */"./md-team/layout7/index.vue"),
    "layout8": ()=>import(/* webpackChunkName: "modules/md-team/layout8" */"./md-team/layout8/index.vue"),
    "layout9": ()=>import(/* webpackChunkName: "modules/md-team/layout9" */"./md-team/layout9/index.vue"),
    "multi-column-info-over-img": ()=>import(/* webpackChunkName: "modules/md-team/multi-column-info-over-img" */"./md-team/multi-column-info-over-img/index.vue"),
    "multi-column-info-under-img": ()=>import(/* webpackChunkName: "modules/md-team/multi-column-info-under-img" */"./md-team/multi-column-info-under-img/index.vue"),
    "single-column-block-intro": ()=>import(/* webpackChunkName: "modules/md-team/single-column-block-intro" */"./md-team/single-column-block-intro/index.vue"),
    "single-column-fold-intro": ()=>import(/* webpackChunkName: "modules/md-team/single-column-fold-intro" */"./md-team/single-column-fold-intro/index.vue"),
    "single-column-grid-info": ()=>import(/* webpackChunkName: "modules/md-team/single-column-grid-info" */"./md-team/single-column-grid-info/index.vue"),
    "single-column-long-intro": ()=>import(/* webpackChunkName: "modules/md-team/single-column-long-intro" */"./md-team/single-column-long-intro/index.vue"),
    "single-column-more-info": ()=>import(/* webpackChunkName: "modules/md-team/single-column-more-info" */"./md-team/single-column-more-info/index.vue"),
    "single-column-subdomain": ()=>import(/* webpackChunkName: "modules/md-team/single-column-subdomain" */"./md-team/single-column-subdomain/index.vue"),
    "template6-list": ()=>import(/* webpackChunkName: "modules/md-team/template6-list" */"./md-team/template6-list/index.vue"),
    "template7-detail": ()=>import(/* webpackChunkName: "modules/md-team/template7-detail" */"./md-team/template7-detail/index.vue"),
    "template7-list": ()=>import(/* webpackChunkName: "modules/md-team/template7-list" */"./md-team/template7-list/index.vue"),
    "template8": ()=>import(/* webpackChunkName: "modules/md-team/template8" */"./md-team/template8/index.vue")
    },
    "md-team-desc": {
        "config": ()=>import(/* webpackChunkName: "modules/md-team-desc"*/"./md-team-desc"),
    "broker": ()=>import(/* webpackChunkName: "modules/md-team-desc/broker" */"./md-team-desc/broker/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-team-desc/layout1" */"./md-team-desc/layout1/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-team-desc/layout2" */"./md-team-desc/layout2/index.vue"),
    "layout3": ()=>import(/* webpackChunkName: "modules/md-team-desc/layout3" */"./md-team-desc/layout3/index.vue"),
    "layout4": ()=>import(/* webpackChunkName: "modules/md-team-desc/layout4" */"./md-team-desc/layout4/index.vue"),
    "layout5": ()=>import(/* webpackChunkName: "modules/md-team-desc/layout5" */"./md-team-desc/layout5/index.vue"),
    "layout6": ()=>import(/* webpackChunkName: "modules/md-team-desc/layout6" */"./md-team-desc/layout6/index.vue"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-team-desc/standard" */"./md-team-desc/standard/index.vue"),
    "team-desc-multi": ()=>import(/* webpackChunkName: "modules/md-team-desc/team-desc-multi" */"./md-team-desc/team-desc-multi/index.vue"),
    "team-desc-performance-bgImg": ()=>import(/* webpackChunkName: "modules/md-team-desc/team-desc-performance-bgImg" */"./md-team-desc/team-desc-performance-bgImg/index.vue"),
    "team-desc-simple": ()=>import(/* webpackChunkName: "modules/md-team-desc/team-desc-simple" */"./md-team-desc/team-desc-simple/index.vue"),
    "team-description": ()=>import(/* webpackChunkName: "modules/md-team-desc/team-description" */"./md-team-desc/team-description/index.vue"),
    "team-description-fashion": ()=>import(/* webpackChunkName: "modules/md-team-desc/team-description-fashion" */"./md-team-desc/team-description-fashion/index.vue"),
    "template6": ()=>import(/* webpackChunkName: "modules/md-team-desc/template6" */"./md-team-desc/template6/index.vue"),
    "template7": ()=>import(/* webpackChunkName: "modules/md-team-desc/template7" */"./md-team-desc/template7/index.vue")
    },
    "md-testimonial": {
        "config": ()=>import(/* webpackChunkName: "modules/md-testimonial"*/"./md-testimonial"),
    "agent-reviews": ()=>import(/* webpackChunkName: "modules/md-testimonial/agent-reviews" */"./md-testimonial/agent-reviews/index.vue"),
    "broker-slider-classic": ()=>import(/* webpackChunkName: "modules/md-testimonial/broker-slider-classic" */"./md-testimonial/broker-slider-classic/index.vue"),
    "broker-slider-color": ()=>import(/* webpackChunkName: "modules/md-testimonial/broker-slider-color" */"./md-testimonial/broker-slider-color/index.vue"),
    "broker-slider-page": ()=>import(/* webpackChunkName: "modules/md-testimonial/broker-slider-page" */"./md-testimonial/broker-slider-page/index.vue"),
    "flow-classic": ()=>import(/* webpackChunkName: "modules/md-testimonial/flow-classic" */"./md-testimonial/flow-classic/index.vue"),
    "grid-classic": ()=>import(/* webpackChunkName: "modules/md-testimonial/grid-classic" */"./md-testimonial/grid-classic/index.vue"),
    "grid-progressive": ()=>import(/* webpackChunkName: "modules/md-testimonial/grid-progressive" */"./md-testimonial/grid-progressive/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-testimonial/layout1" */"./md-testimonial/layout1/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-testimonial/layout2" */"./md-testimonial/layout2/index.vue"),
    "layout3": ()=>import(/* webpackChunkName: "modules/md-testimonial/layout3" */"./md-testimonial/layout3/index.vue"),
    "layout4": ()=>import(/* webpackChunkName: "modules/md-testimonial/layout4" */"./md-testimonial/layout4/index.vue"),
    "layout5": ()=>import(/* webpackChunkName: "modules/md-testimonial/layout5" */"./md-testimonial/layout5/index.vue"),
    "layout6": ()=>import(/* webpackChunkName: "modules/md-testimonial/layout6" */"./md-testimonial/layout6/index.vue"),
    "layout7": ()=>import(/* webpackChunkName: "modules/md-testimonial/layout7" */"./md-testimonial/layout7/index.vue"),
    "layout8": ()=>import(/* webpackChunkName: "modules/md-testimonial/layout8" */"./md-testimonial/layout8/index.vue"),
    "list-fashion": ()=>import(/* webpackChunkName: "modules/md-testimonial/list-fashion" */"./md-testimonial/list-fashion/index.vue"),
    "list-standard": ()=>import(/* webpackChunkName: "modules/md-testimonial/list-standard" */"./md-testimonial/list-standard/index.vue"),
    "reviews-online": ()=>import(/* webpackChunkName: "modules/md-testimonial/reviews-online" */"./md-testimonial/reviews-online/index.vue"),
    "reviews-simple": ()=>import(/* webpackChunkName: "modules/md-testimonial/reviews-simple" */"./md-testimonial/reviews-simple/index.vue"),
    "slider-broker": ()=>import(/* webpackChunkName: "modules/md-testimonial/slider-broker" */"./md-testimonial/slider-broker/index.vue"),
    "slider-classic": ()=>import(/* webpackChunkName: "modules/md-testimonial/slider-classic" */"./md-testimonial/slider-classic/index.vue"),
    "slider-fashion": ()=>import(/* webpackChunkName: "modules/md-testimonial/slider-fashion" */"./md-testimonial/slider-fashion/index.vue"),
    "slider-standard": ()=>import(/* webpackChunkName: "modules/md-testimonial/slider-standard" */"./md-testimonial/slider-standard/index.vue"),
    "template6-slider": ()=>import(/* webpackChunkName: "modules/md-testimonial/template6-slider" */"./md-testimonial/template6-slider/index.vue"),
    "template7": ()=>import(/* webpackChunkName: "modules/md-testimonial/template7" */"./md-testimonial/template7/index.vue")
    },
    "md-vendor": {
        "config": ()=>import(/* webpackChunkName: "modules/md-vendor"*/"./md-vendor"),
    "standard": ()=>import(/* webpackChunkName: "modules/md-vendor/standard" */"./md-vendor/standard/index.vue"),
    "template1": ()=>import(/* webpackChunkName: "modules/md-vendor/template1" */"./md-vendor/template1/index.vue"),
    "template4": ()=>import(/* webpackChunkName: "modules/md-vendor/template4" */"./md-vendor/template4/index.vue")
    },
    "md-video": {
        "config": ()=>import(/* webpackChunkName: "modules/md-video"*/"./md-video"),
    "double": ()=>import(/* webpackChunkName: "modules/md-video/double" */"./md-video/double/index.vue"),
    "layout1": ()=>import(/* webpackChunkName: "modules/md-video/layout1" */"./md-video/layout1/index.vue"),
    "layout2": ()=>import(/* webpackChunkName: "modules/md-video/layout2" */"./md-video/layout2/index.vue"),
    "layout3": ()=>import(/* webpackChunkName: "modules/md-video/layout3" */"./md-video/layout3/index.vue"),
    "layout4": ()=>import(/* webpackChunkName: "modules/md-video/layout4" */"./md-video/layout4/index.vue"),
    "list": ()=>import(/* webpackChunkName: "modules/md-video/list" */"./md-video/list/index.vue"),
    "single": ()=>import(/* webpackChunkName: "modules/md-video/single" */"./md-video/single/index.vue")
    },
}