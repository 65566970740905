import util from '../util';
import {
    getRegisEventLabel,
    GLOBAL_REGISTRATION_POP_UP
} from '@/components/sign-log/register-track';
import { getStore } from '../../store';
import { leadTypes, roleNameMap, getLeadTypeLabel, isBuyer, isSeller } from '@/common/const.js';
import { nextTick } from "vue"

// import {registerSyncFun , COOKIE_LEVEL} from '@/components/cookieAuthority/cookieStatus.js';

let ps;

let validate = false;
function validateVueAttr(obj) {
    const timeout = 10000;
    const now = Date.now();
    return validate
        ? new Promise((resolve, reject) => {
            if (!obj.__vueParentComponent) {
                let timer = setInterval(() => {
                    if (Date.now() - now >= timeout) {
                        clearInterval(timer);
                        reject('timeout');
                    } else {
                        if (obj.__vueParentComponent) {
                            console.log(obj, 'find __vueParentComponent');
                            clearInterval(timer);
                            resolve();
                        }
                    }
                }, 300);
            } else {
                resolve();
            }
        })
        : Promise.resolve();
}

// export let SEARCH_STORE_NAME = '';
function getFbEventData(data) {
    const params = data.params;
    const fbData = {};
    if (window.sitePrepareData().searchCenter) {
        const condition = JSON.parse(params?.condition ?? '{}');
        const location = condition.location || condition.Location || {};
        const city = location.city;
        const neighborhood = location.neighborhood;

        if (city) {
            fbData.city = city.map(c => c.split(',')[0]).join(';');
        }
        if (neighborhood) {
            fbData.neighborhood = neighborhood.join(';');
        }
    } else {
        let key = params.key;
        let keywordType = params.keywordType;
        const cityArr = [];
        const neighborhoodArr = [];
        if (keywordType) {
            keywordType = keywordType.split(';');
            key = key.split(';');
            for (let i = 0; i < keywordType.length; i++) {
                if (keywordType[i] === 'city') {
                    cityArr.push(key[i].split(',')[0]);
                }
                if (keywordType[i] === 'neighborhood') {
                    neighborhoodArr.push(key[i]);
                }
            }
        }
        if (cityArr.length > 0) {
            fbData.city = cityArr.join(';');
        }
        if (neighborhoodArr.length > 0) {
            fbData.neighborhood = neighborhoodArr.join(';');
        }
    }
    return fbData;
}

function getRole(data) {
    return data.role || data.roles
}

function getRoleName(role) {
    if (role.includes(',')) {
        return role.split(',').map(r => getRoleName(r)).join(',');
    } else if (/^\d+$/.test(role)) {
        return roleNameMap[role] || role;
    } else {
        return role;
    }
}

export default {
    prepare: function() {
        if (!ps) {
            ps = new Promise(res => {
                window.siteDataLayer = window.siteDataLayer || [];
                window.siteDataLayer.push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js'
                });
                if (util.isReallyUser) {
                    // registerSyncFun(()=>{
                    return util.addScript(
                        'https://www.googletagmanager.com/gtm.js?id=GTM-N2QNBSG&l=siteDataLayer',
                        true
                    ).then(res);
                    // },{
                    //     util,
                    //     level: COOKIE_LEVEL.targeting
                    // });
                }
            });
        }
        return ps;
    },
    sendGa(options = {}) {
        if (!window.siteDataLayer) {
            window.siteDataLayer  = [];
        }
        let { event, eventName, eventCategory, eventAction, eventLabel, eventValue } = options
        window.siteDataLayer.push({
            event: event || 'common-ga-event-custom-direct',
            eventName: eventName || eventCategory,
            eventCategory: eventCategory || '',
            eventAction: eventAction || '',
            eventLabel: eventLabel || '',
            eventValue: eventValue || '',
        });
    },
    // custom_event_bind
    async resetEventHandler(validateArg = false) {
        await nextTick();
        
        let pageStore = getStore('page'),
            trackStore = getStore('track');
        validate = validateArg;
        window.addInputListener =
            window.addInputListener ||
            function(event) {
                let tar = event.target;
                if (
                    (tar.nodeName === 'INPUT' || tar.nodeName === 'TEXTAREA') &&
                    window.siteDataLayer
                ) {
                    let newTime = new Date().getTime();
                    let inputTime = tar.inputTime;
                    if (!inputTime) {
                        tar.inputTime = newTime;
                        window.siteDataLayer.push({
                            event: 'first-input',
                            inputElement: tar,
                            inputTag: tar.nodeName
                        });
                    }
                    window.siteDataLayer.push({
                        event: 'input',
                        inputElement: tar,
                        inputTag: tar.nodeName
                    });
                }
            };
        window.addBlurListener =
            window.addBlurListener ||
            function(event) {
                let tar = event.target;
                if (
                    (tar.nodeName === 'INPUT' || tar.nodeName === 'TEXTAREA') &&
                    window.siteDataLayer
                ) {
                    window.siteDataLayer.push({
                        event: 'blur',
                        blurInputElement: tar,
                        blurInputTag: tar.nodeName
                    });
                }
            };
        window.addKeyDownListener =
            window.addKeyDownListener ||
            function(event) {
                let tar = event.target;
                if (
                    (tar.nodeName === 'INPUT' || tar.nodeName === 'TEXTAREA') &&
                    window.siteDataLayer
                ) {
                    window.siteDataLayer.push({
                        event: 'keydown',
                        keyDownInputElement: tar,
                        keyDownInputTag: tar.nodeName,
                        keyDownKey: event.key,
                        keyDownKeyCode: event.keyCode
                    });
                }
            };
        document.removeEventListener('input', window.addInputListener, false);
        document.addEventListener('input', window.addInputListener, false);
        document.removeEventListener('blur', window.addBlurListener, true);
        document.addEventListener('blur', window.addBlurListener, true);
        document.removeEventListener(
            'keydown',
            window.addKeyDownListener,
            false
        );
        document.addEventListener('keydown', window.addKeyDownListener, false);


        let getPageName = function() {
            let pageName = '';
            if (window.siteAppVm) {
                pageName = pageStore.page.pageKey;
            } else {
                let url = window.location.pathname.replace(/\/$/, '');
                let href = url.split('/');
                pageName =
                        href.length <= 1 ? 'home' : href[href.length - 1];
            }
            return pageName;
        };
        let getDomainName = function() {
            return window.location.hostname.split('.')[0];
        };
        let getFrom = function() {
            let trackFrom = {
                5: 'facebook',
                6: 'google',
                7: 'baidu',
                8: 'adwerx'
            };
            return (
                trackFrom[
                    util.getCookie && util.getCookie('_referer_t')
                ] || 'other'
            );
        };
        let formatData = function(data) {
            let name = data.fullname || data.fullName || '';
            if (!name && (data.firstName || data.lastName)) {
                name =
                        (data.firstName || '') +
                        ' ' +
                        (data.lastName || '');
            }
            let email = '';
            let phone = '';
            return {
                name: name,
                email: email,
                phone: phone,
                role: getLeadTypeLabel(getRole(data) || leadTypes.Buyer),
                from: (data.from || 'normal').toLowerCase()
            };
        };
            
        let getRegisterBlock = function(ele, role) {
            let isPop = ele && ele.classList.contains('pop-form');
            let isChatPop =
                    ele && ele.classList.contains('pop-chat-register');
            let isLeaveMsgPop =
                    ele && ele.classList.contains('leave-msg');
            let isSellBanner =
                    isPop && isSeller(role) &&
                    document.querySelector('.md-sell-banner');
            let isEvaluation =
                    ele && ele.classList.contains('evaluation-register');
            if (isEvaluation) {
                return 'Home Valuation';
            } else if (isSellBanner) {
                return 'Form';
            } else if (isChatPop) {
                return 'Chatbox';
            } else if (isLeaveMsgPop || isPop) {
                return 'Pop up';
            } else {
                return 'Form';
            }
        };
        let getSearchKeyMap = function() {
            let searchMoreEl =
                    document.querySelector('.md-search-more');
            if (searchMoreEl) {
                validateVueAttr(searchMoreEl).then(_ => {
                    let searchData = searchMoreEl.__vueParentComponent?.ctx?.searchData || {};
                    let propertyConfigs = searchData.propertyConfigs || [];
                    let more = searchData.more || [];
                    let searchKeyMap = {};
                    let labelMap = {
                        Price: 'price',
                        Beds: 'beds',
                        Baths: 'baths',
                        'Property Type': 'propertyType',
                        'Purchase Type': 'purchaseType'
                    };
                    propertyConfigs.forEach(function(item) {
                        searchKeyMap[item.kee] = {
                            label: item.label,
                            key: labelMap[item.label] || item.label,
                            more: false
                        };
                    });
                    more.forEach(function(moreItem) {
                        let groupName = moreItem.name;
                        let morePropertyConfigs =
                                moreItem.propertyConfigs || [];
                        morePropertyConfigs.forEach(function(proItem) {
                            if (
                                proItem.kee ===
                                    'purchaseType-For-Sale-Rent'
                            ) {
                                searchKeyMap[proItem.kee] = {
                                    label: proItem.label,
                                    key:
                                            labelMap[proItem.label] ||
                                            proItem.label,
                                    more: false
                                };
                            } else {
                                searchKeyMap[proItem.kee] = {
                                    label: proItem.label,
                                    more: true,
                                    group: groupName
                                };
                            }
                        });
                    });
                    window.searchKeyMap = searchKeyMap;
                });
            }
        };
        let getSortMap = function() {
            let searchEl = document.querySelector('.md-search');
            if (searchEl) {
                validateVueAttr(searchEl).then(_ => {
                    let sortConfigs = searchEl.__vueParentComponent?.ctx?.sortConfigs || [];
                    let sortMap = {};
                    sortConfigs.forEach(function(sortItem) {
                        sortMap[sortItem.value] = sortItem.label;
                    });
                    window.searchSortMap = sortMap;
                    window.isSearchBar =
                            !!searchEl.__vueParentComponent?.ctx?.searchBarOnly;
                });
            }
        };
            // same as gtm variable - mlsListingId
        let get_mlsListingId = function() {
            let mlsListingId = '';
            let detailInfoCtx =
                    window.siteAppVm.getModuleByName('md-detail-info');
            if (detailInfoCtx) {
                mlsListingId =
                        (detailInfoCtx.info && detailInfoCtx.info.mlsListingId) ||
                        '';
            }
            return mlsListingId;
        };
            // same as gtm variable - mlsZipCode
        let get_mlsZipCode = function() {
            let detailInfoCtx =
                    window.siteAppVm.getModuleByName('md-detail-info');
            let mlsZipCode = '';
            if (detailInfoCtx) {
                mlsZipCode =
                        (detailInfoCtx.info && detailInfoCtx.info.zipCode) || '';
            }
            return mlsZipCode;
        };
            // same as gtm variable - agentLicense
        let get_agentLicense = function() {
            let detailInfoCtx =
                    window.siteAppVm.getModuleByName('md-detail-info');
            let agentLicense = '';
            if (detailInfoCtx) {
                agentLicense =
                        (detailInfoCtx &&
                            detailInfoCtx.info &&
                            detailInfoCtx.info.agentLicense) ||
                        '';
            }
            return agentLicense;
        };

        if (!window.searchKeyMap) {
            getSearchKeyMap();
        }
        if (!window.searchSortMap) {
            getSortMap();
        }
        const tempSearchModule = pageStore.findFirstSearchBarModule();
        window.searchAllConditions =
                (tempSearchModule && tempSearchModule.allConditions) ||
                [];
        window.searchBehavior = 'none';
        const _this = this;
        window.siteAppVm.$EventBus.$on('siteTrack', function(data) {
            if (!window.siteDataLayer) {
                return;
            }
            let { trackType, gaPopScenario } = data;
            const searchModule = pageStore.findFirstSearchBarModule();
            if (trackType === 'page-change') {
                window.siteDataLayer.push({
                    event: 'page-change'
                });
                // add page-change event to customSiteDataLayer
                window.customSiteDataLayer?.push({
                    event: 'page-change'
                });
                window.searchAllConditions =
                        (searchModule &&
                            searchModule.allConditions) ||
                        [];
                window.searchBehavior = 'none';
            } else if (trackType === 'search') {
                if (!window.searchKeyMap) {
                    getSearchKeyMap();
                }
                if (!window.searchSortMap) {
                    getSortMap();
                }
                let eventData = window.searchEventData || {
                    event: 'site-search',
                    searchAddress: {
                        oldLabel: '',
                        label: '',
                        oldValue: '',
                        value: '',
                        addLabel: '',
                        addValue: '',
                        deleteLabel: '',
                        deleteValue: ''
                    },
                    searchSort: {
                        oldLabel: '',
                        oldValue: '',
                        label: '',
                        value: '',
                        changeState: 0
                    },
                    pagination: {
                        oldValue: 0,
                        value: 0,
                        changeState: 0
                    }
                };
                eventData.isSearchBar = window.isSearchBar;
                if (window.searchKeyMap) {
                    for (let key in window.searchKeyMap) {
                        let searchKeyItem = window.searchKeyMap[key];
                        if (searchKeyItem.more) {
                            eventData.searchMore = {
                                oldLabel: '',
                                label: '',
                                oldValue: '',
                                value: '',
                                addLabel: '',
                                addValue: '',
                                deleteLabel: '',
                                deleteValue: ''
                            };
                        } else {
                            eventData[searchKeyItem.key] = {
                                oldLabel: '',
                                label: '',
                                oldValue: '',
                                value: '',
                                changeState: 0
                            };
                        }
                    }
                    let newSort = trackStore.sort;
                    let searchSortMap = window.searchSortMap || {};
                    if (eventData.searchSort.value) {
                        eventData.searchSort.oldLabel =
                                eventData.searchSort.label;
                        eventData.searchSort.oldValue =
                                eventData.searchSort.value;
                    } else {
                        eventData.searchSort.oldLabel =
                                searchSortMap[newSort] || newSort;
                        eventData.searchSort.oldValue = newSort;
                    }
                    eventData.searchSort.label =
                            searchSortMap[newSort] || newSort;
                    eventData.searchSort.value = newSort;
                    eventData.searchSort.changeState =
                            eventData.searchSort.oldValue !==
                                eventData.searchSort.value
                                ? 1
                                : 0;
                    let newPageNum = trackStore.page || 1;
                    if (eventData.pagination.value) {
                        eventData.pagination.oldValue =
                                eventData.pagination.value;
                    } else {
                        eventData.pagination.oldValue = newPageNum;
                    }
                    eventData.pagination.value = newPageNum;
                    eventData.pagination.changeState =
                            eventData.pagination.value !==
                                eventData.pagination.oldValue
                                ? 1
                                : 0;
                    if (
                        eventData.pagination.value !==
                            eventData.pagination.oldValue ||
                            eventData.searchSort.oldValue !==
                            eventData.searchSort.value
                    ) {
                        if (
                            window.searchBehavior.indexOf('view') === -1
                        ) {
                            let behavior = window.searchBehavior;
                            window.searchBehavior =
                                    behavior === 'none'
                                        ? 'view'
                                        : behavior + ';' + 'view';
                        }
                    }
                    let newSearchAllConditions =
                            (searchModule &&
                                searchModule.allConditions) ||
                            [];
                    if (
                        newSearchAllConditions.length -
                            window.searchAllConditions.length >
                            1 &&
                            window.searchAllConditions.length === 0
                    ) {
                        let moreLabels = [];
                        let moreValues = [];
                        newSearchAllConditions.forEach(function(
                            conditionItem
                        ) {
                            let dn = conditionItem.dn;
                            if (dn === 'key') {
                                eventData.address = {
                                    oldLabel: conditionItem.labels.join(
                                        conditionItem.split || ';'
                                    ),
                                    label: conditionItem.labels.join(
                                        conditionItem.split || ';'
                                    ),
                                    oldValue: conditionItem.values.join(
                                        conditionItem.split || ';'
                                    ),
                                    value: conditionItem.values.join(
                                        conditionItem.split || ';'
                                    ),
                                    addLabel: '',
                                    addValue: '',
                                    deleteLabel: '',
                                    deleteValue: ''
                                };
                            } else if (dn === 'selected') {
                                conditionItem.values.forEach(function(
                                    sItem,
                                    index
                                ) {
                                    let sItemData =
                                            window.searchKeyMap[sItem] ||
                                            {};
                                    let labelStr =
                                            '[' +
                                            (sItemData.group || '') +
                                            ' - ' +
                                            (sItemData.label || '') +
                                            ']' +
                                            conditionItem.labels[index];
                                    moreLabels.push(labelStr);
                                    moreValues.push(labelStr);
                                });
                            } else {
                                let cItemData =
                                        window.searchKeyMap[dn] || {};
                                if (cItemData.more) {
                                    let labelStr =
                                            '[' +
                                            (cItemData.group || '') +
                                            ' - ' +
                                            (cItemData.label || '') +
                                            ']' +
                                            conditionItem.labels.join(',');
                                    let valueStr =
                                            '[' +
                                            (cItemData.group || '') +
                                            ' - ' +
                                            (cItemData.label || '') +
                                            ']' +
                                            conditionItem.values.join(',');
                                    moreLabels.push(labelStr);
                                    moreValues.push(valueStr);
                                } else if (eventData[cItemData.key]) {
                                    eventData[cItemData.key].oldLabel =
                                            conditionItem.labels.join(',');
                                    eventData[cItemData.key].label =
                                            conditionItem.labels.join(',');
                                    eventData[cItemData.key].oldValue =
                                            conditionItem.values.join(',');
                                    eventData[cItemData.key].value =
                                            conditionItem.values.join(',');
                                }
                            }
                        });
                        eventData.searchMore = {
                            oldLabel: moreLabels.join('|'),
                            label: moreLabels.join('|'),
                            oldValue: moreValues.join('|'),
                            value: moreValues.join('|'),
                            addLabel: '',
                            addValue: '',
                            deleteLabel: '',
                            deleteValue: ''
                        };
                    } else {
                        let addressAddLabels = [];
                        let addressAddValues = [];
                        let addressDeleteLabels = [];
                        let addressDeleteValues = [];
                        let addressMap = {};
                        let addressSplit = ';';
                        let oldMoreLabels = [];
                        let oldMoreValues = [];
                        let moreLabels = [];
                        let moreValues = [];
                        let moreAddLabels = [];
                        let moreAddValues = [];
                        let moreDeleteLabels = [];
                        let moreDeleteValues = [];
                        let moreMap = {};
                        window.searchAllConditions.forEach(function(
                            conditionItem
                        ) {
                            let dn = conditionItem.dn;
                            if (dn === 'key') {
                                addressSplit =
                                        conditionItem.split || ';';
                                eventData.searchAddress.oldLabel =
                                        conditionItem.labels.join(
                                            conditionItem.split || ';'
                                        );
                                addressDeleteLabels =
                                        addressDeleteLabels.concat(
                                            conditionItem.labels
                                        );
                                eventData.searchAddress.oldValue =
                                        conditionItem.values.join(
                                            conditionItem.split || ';'
                                        );
                                addressDeleteValues =
                                        addressDeleteValues.concat(
                                            conditionItem.values
                                        );
                                conditionItem.labels.forEach(function(
                                    label,
                                    index
                                ) {
                                    addressMap[label] = index;
                                });
                            } else if (dn === 'selected') {
                                conditionItem.values.forEach(function(
                                    sItem,
                                    index
                                ) {
                                    let sItemData =
                                            window.searchKeyMap[sItem] ||
                                            {};
                                    let labelStr =
                                            '[' +
                                            (sItemData.group || '') +
                                            ' - ' +
                                            (sItemData.label || '') +
                                            ']' +
                                            conditionItem.labels[index];
                                    oldMoreLabels.push(labelStr);
                                    oldMoreValues.push(labelStr);
                                });
                            } else {
                                let cItemData =
                                        window.searchKeyMap[dn] || {};
                                if (cItemData.more) {
                                    let labelStr =
                                            '[' +
                                            (cItemData.group || '') +
                                            ' - ' +
                                            (cItemData.label || '') +
                                            ']' +
                                            conditionItem.labels.join(',');
                                    let valueStr =
                                            '[' +
                                            (cItemData.group || '') +
                                            ' - ' +
                                            (cItemData.label || '') +
                                            ']' +
                                            conditionItem.values.join(',');
                                    oldMoreLabels.push(labelStr);
                                    oldMoreValues.push(valueStr);
                                } else if (eventData[cItemData.key]) {
                                    eventData[cItemData.key].oldLabel =
                                            conditionItem.labels.join(',');
                                    eventData[cItemData.key].oldValue =
                                            conditionItem.values.join(',');
                                }
                            }
                        });
                        newSearchAllConditions.forEach(function(
                            conditionItem
                        ) {
                            let dn = conditionItem.dn;
                            if (dn === 'key') {
                                eventData.searchAddress.label =
                                        conditionItem.labels.join(
                                            conditionItem.split || ';'
                                        );
                                eventData.searchAddress.value =
                                        conditionItem.values.join(
                                            conditionItem.split || ';'
                                        );
                                conditionItem.labels.forEach(function(
                                    label,
                                    index
                                ) {
                                    let sindex = addressMap[label];
                                    if (sindex === undefined) {
                                        addressAddLabels.push(label);
                                        addressAddValues.push(
                                            conditionItem.values[index]
                                        );
                                    } else {
                                        addressDeleteLabels.splice(
                                            sindex,
                                            1
                                        );
                                        addressDeleteValues.splice(
                                            sindex,
                                            1
                                        );
                                    }
                                });
                            } else if (dn === 'selected') {
                                conditionItem.values.forEach(function(
                                    sItem,
                                    index
                                ) {
                                    let sItemData =
                                            window.searchKeyMap[sItem] ||
                                            {};
                                    let labelStr =
                                            '[' +
                                            (sItemData.group || '') +
                                            ' - ' +
                                            (sItemData.label || '') +
                                            ']' +
                                            conditionItem.labels[index];
                                    moreLabels.push(labelStr);
                                    moreValues.push(labelStr);
                                });
                            } else {
                                let cItemData =
                                        window.searchKeyMap[dn] || {};
                                if (cItemData.more) {
                                    let labelStr =
                                            '[' +
                                            (cItemData.group || '') +
                                            ' - ' +
                                            (cItemData.label || '') +
                                            ']' +
                                            conditionItem.labels.join(',');
                                    let valueStr =
                                            '[' +
                                            (cItemData.group || '') +
                                            ' - ' +
                                            (cItemData.label || '') +
                                            ']' +
                                            conditionItem.values.join(',');
                                    moreLabels.push(labelStr);
                                    moreValues.push(valueStr);
                                } else if (eventData[cItemData.key]) {
                                    eventData[cItemData.key].label =
                                            conditionItem.labels.join(',');
                                    eventData[cItemData.key].value =
                                            conditionItem.values.join(',');
                                }
                            }
                        });
                        moreDeleteLabels =
                                moreDeleteLabels.concat(oldMoreLabels);
                        moreDeleteValues =
                                moreDeleteValues.concat(oldMoreValues);
                        oldMoreLabels.forEach(function(label, index) {
                            moreMap[label] = index;
                        });
                        moreLabels.forEach(function(label, index) {
                            let sindex = moreMap[label];
                            if (sindex === undefined) {
                                moreAddLabels.push(label);
                                moreAddValues.push(moreValues[index]);
                            } else {
                                moreDeleteLabels.splice(sindex, 1);
                                moreDeleteValues.splice(sindex, 1);
                            }
                        });
                        eventData.searchAddress.addLabel =
                                addressAddLabels.join(addressSplit);
                        eventData.searchAddress.addValue =
                                addressAddValues.join(addressSplit);
                        eventData.searchAddress.deleteLabel =
                                addressDeleteLabels.join(addressSplit);
                        eventData.searchAddress.deleteValue =
                                addressDeleteValues.join(addressSplit);
                        eventData.searchMore = {
                            oldLabel: oldMoreLabels.join('|'),
                            label: moreLabels.join('|'),
                            oldValue: oldMoreValues.join('|'),
                            value: moreValues.join('|'),
                            addLabel: moreAddLabels.join('|'),
                            addValue: moreAddValues.join('|'),
                            deleteLabel: moreDeleteLabels.join('|'),
                            deleteValue: moreDeleteValues.join('|')
                        };
                        for (let key in window.searchKeyMap) {
                            let searchKeyItem =
                                    window.searchKeyMap[key];
                            if (!searchKeyItem.more) {
                                let eventDataKey = searchKeyItem.key;
                                eventData[eventDataKey]['changeState'] =
                                        eventData[eventDataKey][
                                            'oldValue'
                                        ] ===
                                            eventData[eventDataKey]['value']
                                            ? 0
                                            : 1;
                            }
                        }
                        if (
                            JSON.stringify(
                                window.searchAllConditions
                            ) !==
                                JSON.stringify(
                                    newSearchAllConditions
                                ) &&
                                window.searchBehavior.indexOf('filter') ===
                                -1
                        ) {
                            let behavior = window.searchBehavior;
                            window.searchBehavior =
                                    behavior === 'none'
                                        ? 'filter'
                                        : behavior + ';' + 'filter';
                        }
                    }
                    window.searchAllConditions = newSearchAllConditions;
                    window.siteDataLayer.push(eventData);
                    window.searchEventData = eventData;
                }
            } else if (trackType === 'search-result') {
                window.siteDataLayer.push({
                    fbEventData: null
                });
                window.siteDataLayer.push({
                    event: 'facebook-pixel-event',
                    fbEventName: 'Search',
                    fbEventData: {
                        content_type: 'home_listing',
                        content_ids: (data.data || []).map(it => it.id),
                        ...getFbEventData(data)
                    }
                });
            } else if (trackType === 'search-location-enter') {
                window.siteDataLayer.push({
                    event: 'search-location-enter',
                    blurInputElement: data.data
                });
            } else if (trackType === 'register') {
                let isPop =
                        data.ele && data.ele.classList.contains('pop-form');
                let isChatPop =
                        data.ele &&
                        data.ele.classList.contains('pop-chat-register');
                let isLeaveMsgPop =
                        data.ele &&
                        data.ele.classList.contains('leave-msg');
                if ((isPop || isLeaveMsgPop) && isBuyer(getRole(data))) {
                    window.siteDataLayer.push({
                        event: 'ads-ga-event',
                        eventCategory: 'register popup',
                        eventAction: 'submit',
                        // eventCategory: (data.from || 'email').toLowerCase() + '-注册-弹框类型-' + (isChatPop ? 2 : 1),
                        // eventAction: '提交',
                        eventLabel: (data.from || 'email').toLowerCase() + '-PopupType-' + (isChatPop ? 2 : 1)
                    });
                    window.siteDataLayer.push({
                        fbEventData: null
                    });
                    window.siteDataLayer.push({
                        event: 'facebook-pixel-event',
                        fbEventName: isChatPop
                            ? 'BuyerLeadFromChatPopup'
                            : 'BuyerLeadFromNormalPopup',
                        fbEventData: {
                            page: getPageName(),
                            site_name: data.fullname,
                            site_lead: ''
                        }
                    });
                }
                let isEvaluation =
                        data.ele &&
                        data.ele.classList.contains('evaluation-register');
                if (isEvaluation) {
                    window.siteDataLayer.push({
                        event: 'ads-ga-event',
                        eventCategory: 'Home Valuation register',
                        eventAction: 'submit',
                        // eventCategory: 'Home Valuation提交个人信息',
                        // eventAction: '提交',
                        eventLabel: ''
                    });
                    window.siteDataLayer.push({
                        fbEventData: null
                    });
                    window.siteDataLayer.push({
                        event: 'facebook-pixel-event',
                        fbEventName: 'SellerLeadHomeEvaluationPage',
                        fbEventData: {
                            page: getPageName()
                        }
                    });
                }

                let blockName = getRegisterBlock(data.ele, getRole(data));
                _this.sendGa({
                    event: 'common-ga-event-' + trackType,
                    eventName: 'register',
                    eventCategory: gaPopScenario,
                    eventAction: getRole(data) || leadTypes.Buyer,
                    eventLabel: `from:${(
                        data.from || 'normal'
                    ).toLowerCase()},block:${blockName},host:${location.host
                            },path:${location.pathname}`
                });
                window.siteDataLayer.push({
                    event: 'ads-ga-event',
                    eventCategory: getDomainName() + '-register-' + getFrom(),
                    eventAction: 'submit',
                    eventLabel: `from:${getFrom()}, id:${data.id}, teamId:${data.teamId}`
                });
                window.customSiteDataLayer?.push({
                    event: 'register',
                    registerRole: getRoleName(getRole(data) || leadTypes.Buyer)
                });
                // one-time register data, (window.registerProxy && window.registerProxy.value)
                window.registerProxy = new Proxy({}, {
                    get(target, key) {
                        const value = target[key];
                        delete target[key];
                        return value;
                    },
                    set(target, key, value) {
                        target[key] = value;
                        return true;
                    }
                });
                window.registerProxy.value = true;
                window.siteDataLayer.push({
                    fbEventData: null
                });
                window.siteDataLayer.push({
                    event: 'facebook-pixel-event',
                    fbEventName: 'CompleteRegistration',
                    fbEventData: {
                        content_name: '',
                        currency: 'USD',
                        value: 1,
                        status: true,
                        role: getRole(data) || leadTypes.Buyer
                    }
                });
            } else if (trackType === GLOBAL_REGISTRATION_POP_UP) {
                window.siteDataLayer.push({
                    event: 'Global Registration Pop-up',
                    eventCategory: data.eventCategory,
                    eventAction: data.eventAction,
                    eventLabel: data.eventLabel
                });
            } else if (trackType === 'img-swipe-change-track') {
                window.siteDataLayer.push({
                    event: 'swipe preview imgs',
                    eventCategory: 'Switch Img',
                    eventAction: 'Swipe Preview Imgs'
                });
            } else if (trackType === 'addHouseInfo') {
                window.siteDataLayer.push({
                    event: 'ads-ga-event',
                    eventCategory: 'sell banner register',
                    eventAction: 'submit',
                    // eventCategory: 'sell信息',
                    // eventAction: '提交',
                    eventLabel: ''
                });
                window.siteDataLayer.push({
                    fbEventData: null
                });
                window.siteDataLayer.push({
                    event: 'facebook-pixel-event',
                    fbEventName: 'SellerLeadSellPage',
                    fbEventData: {
                        page: getPageName()
                    }
                });
            } else if (trackType === 'evaluationSubmitAddress') {
                window.siteDataLayer.push({
                    event: 'ads-ga-event',
                    eventCategory: 'Get Report click',
                    eventAction: 'click',
                    // eventCategory: '点击Get Report',
                    // eventAction: '点击',
                    eventLabel: ''
                });
                _this.sendGa({
                    event: 'common-ga-event-' + trackType,
                    eventName: 'lead-address',
                    eventCategory: gaPopScenario,
                    eventAction: 'forward',
                    eventLabel: data.address
                });
                window.siteDataLayer.push({
                    fbEventData: null
                });
                window.siteDataLayer.push({
                    event: 'facebook-pixel-event',
                    fbEventName: 'ClickHomeEvaluationReport',
                    fbEventData: {
                        page: getPageName()
                    }
                });

                window.siteDataLayer.push({
                    event: 'facebook-pixel-event',
                    fbEventName: 'Lead',
                    fbEventData: {
                        content_name: data.address
                    }
                });
            } else if (trackType === 'ThirdPartyLogin') {
                _this.sendGa({
                    event: 'common-ga-event-' + trackType,
                    eventName: 'ThirdPartyLogin',
                    eventCategory: gaPopScenario,
                    eventAction:
                            (data.from || '').toLowerCase() + '-success',
                    eventLabel: data.user ? 'hasUser' : 'noUser'
                });
            } else if (trackType === 'ThirdPartyLogin-fail') {
                let eventAction =
                        (data.from || '').toLowerCase() + '-failure';
                let eventLabel = '';
                if (data.errorData.error) {
                    eventLabel = 'server error';
                } else {
                    eventLabel =
                            'ThirdPartyLogin error : ' +
                            data.errorData.errorInfo;
                }
                _this.sendGa({
                    event: 'common-ga-event-' + trackType,
                    eventName: 'ThirdPartyLogin',
                    eventCategory: gaPopScenario,
                    eventAction: eventAction,
                    eventLabel: eventLabel
                });
            } else if (trackType === 'login') {
                _this.sendGa({
                    event: 'common-ga-event-' + trackType,
                    eventName: 'lead-login',
                    eventCategory: gaPopScenario,
                    eventAction: 'success',
                    eventLabel: ''
                });
            } else if (trackType === 'questionnaire-submit') {
                _this.sendGa({
                    event: 'common-ga-event-' + trackType,
                    eventName: 'questionnaire-submit',
                    eventCategory: gaPopScenario,
                    eventAction: 'success',
                    eventLabel: ''
                });
            } else if (trackType === 'login-fail') {
                let eventLabel = '';
                if (data.errorData.error) {
                    eventLabel = 'server error';
                } else {
                    eventLabel =
                            'login error : ' + data.errorData.errorInfo;
                }
                _this.sendGa({
                    event: 'common-ga-event-' + trackType,
                    eventName: 'lead-login',
                    eventCategory: gaPopScenario,
                    eventAction: 'failure',
                    eventLabel: eventLabel
                });
            } else if (trackType === 'lead-register-submit') {
                let blockName = getRegisterBlock(data.ele, getRole(data));
                let newData = formatData(data);
                let newTime = new Date().getTime();
                let eleTime = data.ele.eleTime;
                if (!eleTime) {
                    data.ele.eleTime = newTime;
                    _this.sendGa({
                        event: 'common-ga-event-' + trackType,
                        eventName: 'lead-register',
                        eventCategory: gaPopScenario,
                        eventAction: 'submit',
                        eventLabel:
                                'role:' +
                                newData.role +
                                ' from:' +
                                newData.from +
                                ' block:' +
                                blockName +
                                ' ' +
                                getRegisEventLabel()
                    });
                }
                _this.sendGa({
                    event: 'common-ga-event-lead-register-trying',
                    eventName: 'lead-register',
                    eventCategory: gaPopScenario,
                    eventAction: 'trying',
                    eventLabel:
                            'role:' +
                            newData.role +
                            ' from:' +
                            newData.from +
                            ' block:' +
                            blockName +
                            ' name:' +
                            newData.name +
                            ' ' +
                            getRegisEventLabel()
                });
            } else if (trackType === 'lead-register-submit-fail') {
                let blockName = getRegisterBlock(data.ele, getRole(data));
                let newData = formatData(data);
                _this.sendGa({
                    event: 'common-ga-event-' + trackType,
                    eventName: 'lead-register',
                    eventCategory: gaPopScenario,
                    eventAction: 'submit-failure',
                    eventLabel:
                            'role:' +
                            newData.role +
                            ' from:' +
                            newData.from +
                            ' block:' +
                            blockName +
                            ';' +
                            data.errorInfo.name +
                            (!data.errorInfo.value
                                ? ' empty'
                                : '[' +
                                data.errorInfo.value +
                                '] invalid') +
                            ':' +
                            data.errorInfo.msg +
                            ' ' +
                            getRegisEventLabel()
                });
            } else if (trackType === 'lead-register-fail') {
                let blockName = getRegisterBlock(data.ele, getRole(data));
                let newData = formatData(data);
                let eventLabel = getRegisEventLabel() + ' ';
                if (data.errorData.error) {
                    eventLabel =
                            'role:' +
                            newData.role +
                            ' from:' +
                            newData.from +
                            ' block:' +
                            blockName +
                            ';server error';
                } else {
                    eventLabel =
                            'role:' +
                            newData.role +
                            ' from:' +
                            newData.from +
                            ' block:' +
                            blockName +
                            ';register error : ' +
                            data.errorData.errorInfo;
                }
                _this.sendGa({
                    event: 'common-ga-event-' + trackType,
                    eventName: 'lead-register',
                    eventCategory: gaPopScenario,
                    eventAction: 'request-failure',
                    eventLabel: eventLabel
                });
            } else if (trackType === 'mapLoad') {
                _this.sendGa({
                    event: 'common-ga-event-' + trackType,
                    eventName: 'Mapload',
                    eventCategory: gaPopScenario,
                    eventAction: getPageName(),
                    eventLabel: ''
                });
            } else if (trackType === 'map-event') {
                let mapBehaviorArr = [
                    'zoom-in',
                    'zoom-out',
                    'drag',
                    'mapsearch',
                    'draw',
                    'aggregation'
                ];
                if (
                    mapBehaviorArr.indexOf(data.action) !== -1 &&
                        window.searchBehavior.indexOf('map') === -1
                ) {
                    let behavior = window.searchBehavior;
                    window.searchBehavior =
                            behavior === 'none'
                                ? 'map'
                                : behavior + ';' + 'map';
                }
                _this.sendGa({
                    event: 'common-ga-event-' + trackType,
                    eventName: 'map-event',
                    eventCategory: gaPopScenario,
                    eventAction: data.action,
                    eventLabel: data.label || ''
                });
            } else if (trackType === 'favor-click') {
                let detailEle =
                        document.body.querySelector('.md-detail-info');
                window.siteDataLayer.push({
                    fbEventData: null
                });
                window.siteDataLayer.push({
                    event: 'facebook-pixel-event-InitiateCheckout',
                    fbEventName: 'InitiateCheckout'
                });

                if (detailEle && window._LT && data.status === 1) {
                    window._LT._trackEvent(
                        window._eventType['favorite'],
                        get_mlsListingId(),
                        get_mlsZipCode(),
                        get_agentLicense()
                    );
                }
            } else if (trackType === 'schedule_a_tour') {
                let detailEle =
                        document.body.querySelector('.md-detail-info');
                if (detailEle && window._LT) {
                    window._LT._trackEvent(
                        window._eventType['lead'],
                        get_mlsListingId(),
                        get_mlsZipCode(),
                        get_agentLicense()
                    );
                }
            } else if (trackType === 'image-view') {
                window.siteDataLayer.push({
                    event: 'image-view',
                    eventCategory: data.category,
                    eventAction: data.action,
                    eventLabel: data.label || ''
                });
            } else if (trackType === 'image-view-end') {
                window.siteDataLayer.push({
                    event: 'image-view-end',
                    eventCategory: data.category,
                    eventAction: data.action || '',
                    eventLabel: data.label || ''
                });
            }
        });
    }
};