import outsideclick from './outsideclick';
// import appendToBody from './appendToBody';
import loading from './loading';
import sticky from './sticky';
import lazy from './lazyLoad';
import error from './error';
import empty from './empty';
import click from './click';
import tip from './tip';
import animatedNumber from './animatedNumber';

export default (app) => {
    outsideclick(app);
    // appendToBody(app); // appendToBody指令不再使用了, 可以用teleport代替
    loading(app);
    sticky(app);
    lazy(app);
    error(app);
    empty(app);
    click(app);
    tip(app);
    animatedNumber(app);
};
