import { getQueryField } from './common/utils/url-params';

import { proxyCookieUtil } from '@/components/cookieAuthority/cookieStatus.js';


// import { usePageStore } from './store';
export function getQueryWithDecodeKey(globalData) {
    let queryO = JSON.parse(
        JSON.stringify(globalData.URI_REQUEST_PARAMS || {})
    );
    let query = {};
    for (let key in queryO) {
        try {
            if (key === 'mapSearch' && queryO[key] === 'false') {
                query[key] = false;
            } else {
                query[decodeURIComponent(key)] = decodeURIComponent(queryO[key]);
            }
        } catch (e) {
            console.error(e);
        }
    }
    return query;
}
const getIsMobile = (ua, queryObj) =>
    /iphone|ipad|ipod|android|windows phone/i.test(ua) ||
    queryObj['isMobile'] === '1';
const getIsBot = ua =>
    /bot|InspectionTool|rv:34\.0|Crawler|YandexImages|spider|MegaIndex|Barkrowler|Sitemaps|jndi:/i.test(
        ua
    );
const getIsPageSpeed = ua => /Chrome-Lighthouse|GTmetrix|Lighthouse/i.test(ua);
const getIsIos = ua => /iphone|ipad|ipod/i.test(ua);
const getIsIphone = ua => /iphone/i.test(ua);
const getInApp = ua => /chime/i.test(ua);

const getIsSafari = ua => /^((?!chrome|android).)*safari/i.test(ua);

export const getLocation = function(globalData) {
    return {
        get url() {
            return `${location.pathname}${location.search}`;
        },
        get origin() {
            return `${location.protocol}//${location.host}`;
        },
        get href() {
            return location.href.split('#')[0];
        },
        get path() {
            return location.pathname;
        },
        get pathname() {
            return location.pathname;
        },
        get query() {
            let params = getQueryField();
            if (params.searchId) {
                return getQueryWithDecodeKey(globalData);
            }
            return params;
        },
        get querystring() {
            return location.search.slice(1);
        },
        get hostname() {
            return location.hostname;
        },
        get protocol() {
            return location.protocol;
        },
        get hash() {
            return location.hash;
        },
        get search() {
            return location.search;
        }
    };
};

export const getPlatform = function(queryObj) {
    var ua = navigator.userAgent;
    var isMobile = getIsMobile(ua, queryObj),
        isBot = getIsBot(ua),
        isPageSpeed = getIsPageSpeed(ua),
        isReallyUser = !(isPageSpeed || isBot),
        isScreenshot = !!queryObj['screenshot'],
        isIos = getIsIos(ua),
        isIphone = getIsIphone(ua),
        isSafari = getIsSafari(ua),
        inApp = getInApp(ua);
    return {
        ua,
        isMobile,
        isBot,
        isPageSpeed,
        isReallyUser,
        isScreenshot,
        isIos,
        isAndroid: isMobile && !isIos,
        isIphone,
        isSafari,
        inApp
    };
};

export const cookieMethods = function() {
    const cookieUtil = {
        /**
         *  return corresponding cname of cookie value
         */
        getCookie(cname) {
            const name = cname + '=';
            try {
                const ca = document.cookie.split(';');
                for (let i = 0, len = ca.length; i < len; i++) {
                    let c = ca[i];
                    while (c.charAt(0) === ' ') {
                        c = c.substring(1); 
                    }
                    if (c.indexOf(name) === 0) {
                        return c.substring(name.length, c.length); 
                    }
                }
            } catch (err) {}
            return '';
        },
        setCookie(cname, cvalue, exdays, domain) {
            try {
                let expires = '';
                if (typeof exdays !== 'undefined' && exdays !== null) {
                    const d = new Date();
                    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
                    expires = ';expires=' + d.toUTCString();
                }
                let str = `${cname}=${cvalue};path=/${expires}`;
                if (domain) {
                    str += `;domain=${domain}`;
                }
                document.cookie = str;
            } catch (err) {}
        },
        removeCookie(cname) {
            document.cookie = `${cname}=;path=/;expires=${new Date(
                -1
            ).toUTCString()}`;
        }
    };
    proxyCookieUtil(cookieUtil);
    return cookieUtil
};
