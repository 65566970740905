import types from '../type';
import { usePageStore } from '@/store';

const dateFormats = {
    yyyy: function() {
        return this.getFullYear();
    },
    yy: function() {
        return this.getFullYear() % 100;
    },
    MM: function() {
        return this.getMonth() + 1;
    },
    MMM: function(pinia) {
        let pageStore = usePageStore(pinia);
        return pageStore.monthSimpleNames[this.getMonth()];
    },
    MMMM: function(pinia) {
        let pageStore = usePageStore(pinia);
        return pageStore.monthNames[this.getMonth()];
    },
    dd: function() {
        return this.getDate();
    },
    ww: function(pinia) {
        let pageStore = usePageStore(pinia);
        return pageStore.weekNames[this.getDay()];
    },
    HH: function() {
        return this.getHours();
    },
    hh: function() {
        var hour = this.getHours();
        return hour <= 12 ? (hour === 0 ? 12 : hour) : hour - 12;
    },
    mm: function() {
        return this.getMinutes();
    },
    ss: function() {
        return this.getSeconds();
    },
    sss: function() {
        return this.getMilliseconds();
    },
    p: function() {
        return this.getHours() >= 12 ? 'PM' : 'AM';
    }
};
/**
 *  date formatting 
 * @param {string} fmt  format mode  ( See dateFormats object )
 * @param {Date|Number|String} date object or timestamp or string
 * eg: 2020-07-24 18:37:10
 *  Year ：yyyy -> 2020,  yy ->  20 ( take the last two digits )
 *  moon ：MM ->  07, MMM -> Jul, MMMM -> July
 *  day ：dd ->  24
 *  Week ：ww ->  Fri
 *  hour : HH ->  18, hh -> 06(12 hour system )
 *  point : mm -> 37
 *  Second : ss -> 10
 *  millisecond ：sss
 * p： PM or AM
 */
export default function format(fmt = 'yyyy-MM-dd hh:mm:ss', date = new Date(), pinia) {
    let newDate = new Date()
    if (typeof date === 'string' && date) {
        newDate = new Date(date.replace(/-/g, '/'))
    } else if (typeof date === 'number') {
        newDate = new Date(date)
    } else if (date instanceof Date) {
        newDate = new Date(date.getTime())  // Time Zone Conversion
    }
    var fmts = fmt.match(/(\w)\1*/g).sort();
    for (var i = fmts.length - 1; i >= 0; i--) {
        let fmtValue = dateFormats[fmts[i]] && dateFormats[fmts[i]].call(newDate, pinia);
        if (types.isNull(fmtValue)) {
            continue;
        }
        if (!isNaN(fmtValue) && fmtValue < 10) {
            fmtValue = '0' + fmtValue;
        }
        fmt = fmt.replace(fmts[i], fmtValue);
    }
    return fmt;
}
