import util from "@/common/util";


// Format the animated number with commas
const formatNumber = (originalText, animatedNumber) => {
    let arr = animatedNumber.toString().split(".");
    return originalText.replace(/[\d,]+(?:\.\d+)?/, animatedNumber.toLocaleString(undefined, {
        minimumFractionDigits: arr[1]?.length || 0,
        maximumFractionDigits: arr[1]?.length || 0,
    }));
};
// Extract number from text
const extractNumber = (text) => {
    const match = text.match(/[\d,]+(?:\.\d+)?/);
    return match ? parseFloat(match[0].replace(/,/g, '')) : null;
};
const animateParse = (el, container, options) => {
    if (!el || el.nodeType != 1) {
        return;
    }
    let children = el.children || [];
    if (children.length) {
        for (let i = 0; i <  children.length; i++) {
            animateParse(children[i], container, options);
        }
    } else {
			
        animateRun({ el, container, options });
    }
}
// Animate number from start to end
const animateRun = ({ el, options, container }) => {
    if (el.__animated) {
        return; 
    }
    const originalText = el.textContent.trim();
    const end = extractNumber(originalText);
		
    if (!end) {
        return;
    }
    el.__animated = true;
    const duration = options.duration || 3000;
    const uid = container.uid;
    let startTime = null;
    let start = 0;
    const step = (timestamp) => {
        if (container.uid != uid) {
            console.log(container.uid, uid)
            el.textContent = formatNumber(originalText, end);
            return;
        }
        if (!startTime) {
            startTime = timestamp; 
        }
        const progress = Math.min((timestamp - startTime) / duration, 1);
        const currentNumber = Math.floor(progress * (end - start) + start);
        el.textContent = formatNumber(originalText, currentNumber);
    
        if (progress < 1) {
            requestAnimationFrame(step);
        } else {
            el.textContent = formatNumber(originalText, end);
        }
    };
    requestAnimationFrame(step);
};
			
const animatedPlugin = (container, binding) => {
    let options = binding.value || {};
    const duration = options; duration || 3000;
    const uid = (container.uid || 1) + 1;
    container.uid = uid;

    // Process numbers inside the element
    const processNumbers = () => {
        const numberElements = container.querySelectorAll(options.arg || '.number');
        numberElements.forEach((element) => {
            if (element.__animated) {
                return; 
            }
            animateParse(element, container, options);
        });
    };
                   
    // Intersection observer to trigger animations when the element is in view
    let isIntersecting = false;
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !isIntersecting) {
                    isIntersecting = true;
                    processNumbers();
                    observer.unobserve(container);
                    container._mutationObserver  = new MutationObserver(() => {
                        processNumbers();
                    });
                    container._mutationObserver.observe(container, { childList: true, subtree: true });
                }
            });
        },
        { threshold: 0.2 }
    );
       
    if (container._mutationObserver) {
        container._mutationObserver.disconnect();
    }
    if (container._intersectionObserver) {
        container._intersectionObserver.disconnect();
    }
       
    observer.observe(container);
                   
    // Mutation observer to reprocess the numbers if the content changes
    container._intersectionObserver = observer;
}

export default (Vue) => {
    Vue.directive("animated-number", {
        updated(el, binding) {
            if (util.inCms) {
                const uid = (el.uid || 1) + 1;
                el.uid = uid;
            }
        },
        mounted(el, binding) {
            if (util.isSSRServer) { 
                return;
            }
            animatedPlugin(el, binding);
        },
        unmounted(el) {
            if (el._mutationObserver) {
                el._mutationObserver.disconnect(); 
            }
            if (el._intersectionObserver) {
                el._intersectionObserver.disconnect(); 
            }
        },
    });
}

